import { faEye, faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AudioChipWithTranscript from "components/core/AudioChipWithTranscript";
import CenterLoading from "components/core/CenterLoading";
import ViewPhoto from "components/notification/ViewPhoto";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getAudioID, setAudioID } from "reduxStore/audioplayer";
import users, {
  RejectVariationRequestDto,
  SignVariationDto,
  VariationDetailsDto,
} from "services/users";
import SignaturePad from "signature_pad";
import { dataURLToBlob, isUUID } from "utils/CommonUtils";
import { useTitle } from "utils/UseTitleUtils";

type Props = {};

declare type errorDto = {
  signature: string;
};

const AcceptWorkVariation = (props: Props) => {
  useTitle("Accept Work Variation");
  const { variationUUID } = useParams();
  const dispatch = useDispatch();
  if (!isUUID(variationUUID)) {
    return <Navigate to={"/home"} replace />;
  }

  const canvasRef = useRef(null);
  const signPadRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<errorDto>();
  const audioID = useSelector(getAudioID);
  const [{ variation, contact, id }, setVariationDetails] =
    useState<VariationDetailsDto>({
      variation: undefined,
      contact: undefined,
      id: undefined,
    });

  const priceTypes = {
    flat_rate: "Flat Rate",
    hourly: "Hourly Rate",
    agreed_contract_rate: "Agreed Contract Rate",
  };

  const handleClear = () => {
    signPadRef.current.clear();
  };

  const resizeCanvas = () => {
    if (!canvasRef.current) return;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvasRef.current.width = canvasRef.current.offsetWidth * ratio;
    canvasRef.current?.getContext("2d").scale(ratio, ratio);
    handleClear(); // otherwise isEmpty() might return incorrect value
  };

  const uploadSignature = async (onSignImage: string) => {
    const file = dataURLToBlob(onSignImage!);
    const formData = new FormData();
    formData.append("fileType", "variation_signature_photo");
    formData.append("file", file, "sign.png");

    try {
      setIsLoading(true);
      const response = await users.uploadVariationRequestFile(
        variationUUID,
        formData
      );
      return response.id;
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const validate = () => {
    const errs: errorDto = {
      signature: "",
    };
    if (signPadRef.current.isEmpty()) {
      errs.signature = "Please complete the signature";
    }

    if (errs.signature) {
      setErrors(errs);
      return false;
    } else {
      return true;
    }
  };

  const acceptWorkVariation = async () => {
    if (!validate()) return;
    const dataURL = signPadRef.current.toDataURL();
    const signatureId = await uploadSignature(dataURL);

    try {
      setIsLoading(true);
      const payload: SignVariationDto = {
        signingRequestId: variationUUID,
        type: "recipient",
        contactId: contact.id,
        fileId: signatureId,
        variationId: variation.id,
      };
      const response = await users.signVariationRequest(payload);
      if (response) {
        getVariationDetails();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const rejectWorkVariation = async () => {
    try {
      setIsLoading(true);
      const payload: RejectVariationRequestDto = {
        signingRequestId: variationUUID,
        action: "reject",
      };

      const response = await users.rejectVariationRequest(payload);
      if (response) {
        getVariationDetails();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getVariationDetails = async () => {
    try {
      setIsLoading(true);
      const response = await users.getVariationByUUID(variationUUID);
      setVariationDetails(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const formatPriceString = (): string => {
    if (variation.pricingType === "flat_rate") {
      return `$ ${variation.price}`;
    }
    if (variation.pricingType === "hourly") {
      return `$ ${variation.price} /Hr`;
    }
    return "N/A";
  };

  function errorMessage(field: string) {
    if (!errors) return null;
    if (typeof errors[field] === "undefined") return null;

    return (
      <Form.Control.Feedback
        type="invalid"
        className="font-color-red poppins text-start d-block"
      >
        {errors[field]}
      </Form.Control.Feedback>
    );
  }

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const signPad = new SignaturePad(canvas, {
        backgroundColor: "rgba(255,255,255,1)",
        penColor: "rgb(0, 0, 0)",
      });
      signPadRef.current = signPad;
    }
  }, [canvasRef.current, variation?.status]);

  useEffect(() => {
    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();
  }, [canvasRef.current, variation?.status]);

  useEffect(() => {
    getVariationDetails();
  }, []);

  return (
    <div className="p-3 p-md-5">
      <CenterLoading isVisible={isLoading} />
      <h1 className="h1-text font-color-black">Work Variation</h1>
      <h4 className="pb-3 border-bottom">Work Variation Details:</h4>
      <div className="row pb-3 border-bottom g-3 mt-3">
        <div className="col-12 col-md-6">
          <Form.Group className="mb-3" controlId="variation-name">
            <Form.Label>Work Variation Name:</Form.Label>
            <Form.Control
              type="text"
              value={variation?.name || ""}
              className="poppins"
              disabled
            />
          </Form.Group>
          <div className=" row g-2 justify-content-between mb-3">
            <Form.Group
              className="col-12 col-md-6"
              controlId="variation-number"
            >
              <Form.Label>Variation Number: (Optional)</Form.Label>
              <Form.Control
                type="text"
                value={variation?.variationNumber ?? "N/A"}
                disabled
                className="poppins"
              />
            </Form.Group>
            <Form.Group
              className="col-12 col-md-6"
              controlId="site-instruction-number"
            >
              <Form.Label>Site Instruction Number: (Optional)</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={variation?.siteInstructionNumber ?? "N/A"}
                className="poppins"
              />
            </Form.Group>
          </div>
          <Form.Group className="mb-3" controlId="work-scope">
            <Form.Label>Details of Variation Work and Scope:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Details of Variation"
              value={variation?.scope || "N/A"}
              disabled
              as={"textarea"}
              style={{ minHeight: 130 }}
            />
          </Form.Group>
          {variation?.files.filter(
            (e) => e.fileType === "variation_scope_audio"
          ).length > 0 && (
            <div className="mb-3">
              <AudioChipWithTranscript
                audioData={
                  variation?.files.filter(
                    (e) => e.fileType === "variation_scope_audio"
                  )[0] || {}
                }
                isPlaying={
                  variation?.files.filter(
                    (e) => e.fileType === "variation_scope_audio"
                  )[0]?.id === audioID
                }
                onClickPlay={(e) => dispatch(setAudioID(e.id))}
              />
            </div>
          )}
          <div className="mb-3">
            <span className="fw-600">
              Details of Variation Work and Scope Upload:
            </span>
            {variation?.files
              .filter((e) => e.fileType === "variation_scope_file")
              .map((file, id) => (
                <div
                  key={id}
                  className="border border-dark my-2 g-0 light-gray-bg p-2 px-3 align-item-center rounded row"
                >
                  <div className="lh-1-only col-auto pe-3">
                    <FontAwesomeIcon
                      icon={faImage}
                      className="d-inline-block m-auto fs-4"
                    />
                  </div>
                  <span className="body-text col m-auto text-start file-text-overflow">
                    {file.name}
                  </span>

                  <a
                    href={file.inlineUrl}
                    target="_blank"
                    className="text-dark lh-1-only col-auto text-end ms-3"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="d-inline-block m-auto fs-5 fa-sharp"
                    />
                  </a>
                </div>
              ))}
          </div>

          <Form.Group className="mb-3" controlId="additional-notes">
            <Form.Label>Additional Notes:</Form.Label>
            <Form.Control
              type="text"
              value={variation?.additionalNotes || "N/A"}
              disabled
              as={"textarea"}
              style={{ minHeight: 130 }}
            />
          </Form.Group>
          {variation?.files.filter(
            (e) => e.fileType === "variation_notes_audio"
          ).length > 0 && (
            <div className="mb-3">
              <AudioChipWithTranscript
                audioData={
                  variation?.files.filter(
                    (e) => e.fileType === "variation_notes_audio"
                  )[0] || {}
                }
                isPlaying={
                  variation?.files.filter(
                    (e) => e.fileType === "variation_notes_audio"
                  )[0]?.id === audioID
                }
                onClickPlay={(e) => dispatch(setAudioID(e.id))}
              />
            </div>
          )}
        </div>
        <div className="col-12 col-md-6">
          <span className="fw-bold">Pricing Type:</span>
          <div className="row g-0 mb-3 justify-content-between">
            <Form.Check
              id="flat_rate"
              className="col-6 py-2"
              type="radio"
              label={priceTypes[variation?.pricingType]}
              defaultChecked
            />
            {variation?.price && (
              <Form.Control
                type="text"
                placeholder="$0.00"
                className="col-6 w-auto"
                value={variation ? formatPriceString() : "N/A"}
                disabled
                min={0}
              />
            )}
          </div>
          <div className="mb-3">
            <ViewPhoto
              imageFiles={variation?.files?.filter(
                (e) => e.fileType == "variation_photo"
              )}
              title="Photos of Variation"
              imageTitleText="  "
            />
          </div>
          <div className="mb-3">
            <span className="fw-600">Additional Files:</span>
            {variation?.files
              .filter((e) => e.fileType === "variation_additional_file")
              .map((file, id) => (
                <div
                  key={id}
                  className="border border-dark my-2 g-0 light-gray-bg p-2 px-3 align-item-center rounded row"
                >
                  <div className="lh-1-only col-auto pe-3">
                    <FontAwesomeIcon
                      icon={faImage}
                      className="d-inline-block m-auto fs-4"
                    />
                  </div>
                  <span className="body-text col m-auto text-start file-text-overflow">
                    {file.name}
                  </span>

                  <a
                    href={file.inlineUrl}
                    target="_blank"
                    className="text-dark lh-1-only col-auto text-end ms-3"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="d-inline-block m-auto fs-5 fa-sharp"
                    />
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="row pb-3 border-bottom g-3 mt-3">
        <div className="col-12 col-lg-6 d-flex flex-column">
          <span className="fw-bold mb-2 d-block">Variation created by:</span>
          <div className=" border flex-grow-1 border-dark bg-light-grey p-3">
            <div className="row">
              <div className="col-12 col-sm-4 d-flex gap-2 align-items-baseline d-sm-block">
                <h6 className="fw-600">Name: </h6>
                <span className=" body-text">
                  {variation?.createdBy.firstName}{" "}
                  {variation?.createdBy.lastName}
                </span>
              </div>
              <div className="col-12 col-sm-4 d-flex gap-2 align-items-baseline d-sm-block">
                <h6 className="fw-600">Employer: </h6>
                <span className="body-text">
                  {variation?.createdBy.organizationName}
                </span>
              </div>
              <div className="col-12 col-sm-4 d-flex gap-2 align-items-baseline d-sm-block">
                <h6 className="fw-600">Current Date/Time: </h6>
                <span className="body-text">
                  {moment(variation?.createdOn).format("MMM DD, YYYY hh:mm a")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 d-flex flex-column">
          <span className="fw-bold mb-2 d-block">Signature:</span>
          <div className="border border-dark h-100">
            <img
              src={
                variation?.signatures?.find((e) => e.type === "creator")
                  ?.signatureFile.inlineUrl
              }
              alt={`${variation?.createdBy.firstName}'s signature`}
            />
          </div>
        </div>
      </div>
      <div className="row pb-3 border-bottom g-3 mt-3">
        <div className="col-12 col-lg-6 d-flex flex-column">
          <span className="fw-bold mb-2 d-block">Variation accepted by:</span>
          <div className=" border flex-grow-1 border-dark bg-light-grey p-3">
            <div className="row">
              <div className="col-12 col-sm-4 d-flex gap-2 align-items-baseline d-sm-block">
                <h6 className="fw-600">Name: </h6>
                <span className=" body-text">
                  {contact?.firstName} {contact?.familyName}
                </span>
              </div>
              <div className="col-12 col-sm-4 d-flex gap-2 align-items-baseline d-sm-block">
                <h6 className="fw-600">Employer: </h6>
                <span className="body-text">{contact?.organizationName}</span>
              </div>
              <div className="col-12 col-sm-4 d-flex gap-2 align-items-baseline d-sm-block">
                <h6 className="fw-600">Current Date/Time: </h6>
                <span className="body-text">
                  {moment(new Date()).format("MMM DD, YYYY hh:mm a")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 d-flex flex-column">
          <span className="d-block fw-bold mb-2">Signature:</span>
          {variation?.signatures?.some((e) => e.type === "recipient") && (
            <div className="border border-dark h-100">
              <img
                src={
                  variation?.signatures?.find((e) => e.type === "recipient")
                    ?.signatureFile.inlineUrl
                }
                alt={`${variation?.createdBy.firstName}'s signature`}
              />
            </div>
          )}

          {variation?.status === "pending" && (
            <div className="position-relative flex-grow-1">
              <canvas
                ref={canvasRef}
                className="border border-dark signature-pad h-100 w-100"
              ></canvas>

              <button
                title="reset sign"
                aria-label="Reset Sign"
                className="btn bottom-0 end-0 position-absolute primary-btn-black flat-btn"
                id="clear"
                onClick={() => handleClear()}
              >
                Reset Sign
              </button>
              {errorMessage("signature")}
            </div>
          )}
          {variation?.status === "rejected" && (
            <div className="align-item-center border border-dark d-flex h-100 justify-content-center">
              <h1 className="text-danger align-self-center my-4 m-0 p-0">
                VARIATION REJECTED
              </h1>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="row mt-4 justify-content-between">
          <div className="col-12 col-sm-auto">
            <button
              title="back"
              aria-label="Navigate Back"
              type="button"
              className="btn h-100 primary-btn-black w-100"
              onClick={() => navigate("/")}
            >
              BACK
            </button>
          </div>
          {variation?.status === "pending" && (
            <div className="col-12 col-sm-9 d-flex flex-column flex-sm-row justify-content-end">
              <div className="col-12 col-sm-4 my-2 my-sm-0">
                <button
                  title="reject"
                  aria-label="Reject"
                  type="button"
                  className="btn h-100 primary-btn-black w-100"
                  onClick={() => {
                    rejectWorkVariation();
                  }}
                >
                  REJECT
                </button>
              </div>
              <div className="col-12 col-sm-4 ps-sm-3">
                <button
                  title="accept"
                  aria-label="Accept"
                  type="button"
                  className="btn h-100 primary-btn w-100"
                  onClick={() => {
                    acceptWorkVariation();
                  }}
                >
                  ACCEPT
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcceptWorkVariation;
