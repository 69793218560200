import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { useTitle } from "../../utils/UseTitleUtils";

interface EmailVerificationErrorProps {}

const ERRORS = {
  EMPTY: "Activation code cannot be empty",
  INVALID: "Activation code invalid",
  NOT_FOUND: "Activation code cannot be found",
  ALREADY_ACTIVE:
    "Activation has already been completed for this account.  Please check your account for the Confirmation email.",
};

const EmailVerificationError = (props: EmailVerificationErrorProps) => {
  const [params] = useSearchParams();
  useTitle("Activation failed");
  const error = params.get("error");

  return (
    <div className="content-section d-flex poppins">
      <div className="col-12 col-lg-7 m-auto text-center">
        <div className="align-items-center d-flex flex-column vertical-center">
          <img
            src={
              error === "ALREADY_ACTIVE"
                ? `/assets/svgs/user_tick.svg`
                : `/assets/svgs/user_cancel.svg`
            }
            className="mb-4"
            alt="activation failed"
            style={{ height: 100, width: 100 }}
          />
          <h1
            className="h1-text poppins"
            style={
              error === "ALREADY_ACTIVE"
                ? { color: "#1d65a6" }
                : { color: "#ff0000" }
            }
          >
            {error === "ALREADY_ACTIVE"
              ? "Already activated"
              : "Activation failed!"}
          </h1>
          <div className="col-12 col-lg-11 col-md-10 pt-4 px-4">
            {error === "ALREADY_ACTIVE" ? (
              <span className="body-text d-block mb-3 lh-2">
                {ERRORS[error]}
              </span>
            ) : (
              <span className="body-text d-block mb-3 lh-2">
                Your email has not been successfully confirmed.
                <br />
                Please contact support or try again later.
              </span>
            )}
            <span className="body-text d-block mb-4 lh-2 fw-bold font-color-red">
              {error !== "ALREADY_ACTIVE" && ERRORS[error]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationError;
