import { faImage, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

import { FileDto } from "services/users";
import { openInNewTab } from "utils/CommonUtils";

declare type FileSelectProps = {
  label?: string;
  subTitle?: string;
  name?: string;
  buttonName?: string;
  showSelectedFile?: boolean;
  disabled?: boolean;
  onlyFileView?: boolean;

  formGroupClassName?: string;
  btnParentClassName?: string;
  // You will need to assign class for changing theme of button
  buttonClassName?: string;
  controlId?: string;
  accept: string;
  error?: string;
  defaultFile?: FileDto;
  fileName?: string;
  reverseOrder?: boolean;

  /**
   * file is the selected file and updateDefault is the boolean which will let you know that
   * to remove default value or not.
   */
  onSelectFile?: (file?: File, updateDefault?: boolean) => void;
};

const FileSelect = ({
  label,
  name,
  subTitle,
  buttonName,
  showSelectedFile,
  defaultFile,
  fileName,
  disabled,
  onlyFileView = false,
  formGroupClassName,
  btnParentClassName,
  buttonClassName,
  controlId,
  accept,
  reverseOrder = false,
  error,
  onSelectFile,
}: FileSelectProps) => {
  const otherFileInput = useRef<HTMLInputElement>();

  const [selectedFile, setSelectedFile] = useState("");

  // update file on parent component
  useEffect(() => {
    const file = otherFileInput.current?.files[0];

    if (onSelectFile)
      onSelectFile(
        selectedFile && selectedFile.trim() == "" ? undefined : file
      );
  }, [selectedFile]);

  useEffect(() => {
    /**
     * Check if file is updated and exist,
     * set selected file to blank and continue
     */
    // if (defaultFile) {
    otherFileInput.current.value = "";
    setSelectedFile("");
    // }
  }, [disabled]);

  const file = otherFileInput.current?.files[0] as FileDto;

  const onClose = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e) e.preventDefault();

    otherFileInput.current.value = "";
    setSelectedFile("");

    if (defaultFile) {
      onSelectFile(undefined, true);
    }
  };

  return (
    <Form.Group
      className={formGroupClassName}
      controlId={controlId ?? "otherFiles"}
    >
      {label && <Form.Label>{label}</Form.Label>}

      {subTitle && (
        <span className="fst-italic d-block body-text lh-2 mb-3">
          {subTitle}
        </span>
      )}
      <div className={btnParentClassName ?? `col-12 col-sm-auto`}>
        <div
          className={`d-flex ${
            reverseOrder ? "flex-column-reverse" : "flex-column"
          }`}
        >
          {((file && selectedFile.trim() != "") || defaultFile) && (
            <div className="border border-dark g-0 light-gray-bg my-3 p-2 px-3 rounded row">
              <div className="lh-1-only col-auto pe-3">
                <FontAwesomeIcon
                  icon={faImage}
                  className="d-inline-block m-auto fs-4"
                />
              </div>

              <span
                className="body-text col m-auto text-start file-text-overflow"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const newFile: FileDto = file ? file : defaultFile;

                  var modalImg = document.getElementById("image-view-content");
                  document.getElementById("image-view-overlay").style.display =
                    "block";
                  document.getElementById("image-view").style.display = "block";

                  document.body.classList.add("stop-scroll-body");
                  if (modalImg != null) {
                    (modalImg as HTMLImageElement).src = newFile?.inlineUrl
                      ? newFile?.inlineUrl
                      : URL.createObjectURL(newFile as MediaSource);
                  }
                }}
              >
                {file ? file.name : fileName || defaultFile.name}
              </span>

              <div
                tabIndex={disabled ? -1 : 0}
                role={"button"}
                className="lh-1-only col-auto text-end ms-3"
                onKeyPress={(e) => {
                  if (e.key == " " || e.key == "Enter") {
                    e.currentTarget.click();
                    e.preventDefault();
                  }
                }}
                onClick={onClose}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  className="d-inline-block m-auto fs-4"
                />
              </div>
            </div>
          )}
          {!onlyFileView && (
            <button
              type="button"
              title="upload file"
              aria-label="Upload File"
              disabled={disabled}
              className={`align-items-center btn nav-btn w-100 ${
                buttonClassName ?? "primary-btn primary-btn-black"
              }`}
              onClick={(e) => {
                if (otherFileInput.current) otherFileInput.current.click();
              }}
            >
              {buttonName ?? "Upload File"}
            </button>
          )}
        </div>
      </div>
      <Form.Control
        name={name ?? "otherFiles"}
        disabled={disabled}
        ref={otherFileInput}
        accept={accept ?? "*"}
        type="file"
        hidden
        onChange={(e) => {
          setSelectedFile(otherFileInput.current?.files[0]?.name);
        }}
      />
      {error && (
        <span className="font-color-red poppins d-block body-text mt-2">
          {error}
        </span>
      )}
    </Form.Group>
  );
};

export default FileSelect;
