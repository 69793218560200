import { AuthData, UserProfile } from "../redux/auth/index";

import { SetupAccount } from "../pages/auth/AccountSetup";

import { AddressData } from "../components/address";
import { RegisterStaffRequest } from "./auth";
import { doFetch } from "./fetcher";
import { NotificationDto } from "./notification";
import { PurchasesRecordDto } from "pages/pah/worksites/jobs/RecordedPurchases";
import { WHSWorkActivity } from "./swms";

export declare type ContactPurpose =
  | "Company"
  | "CompanyStaff"
  | "Contractor"
  | "ContractorStaff"
  | "Supplier"
  | "Visitor"
  | "WhsPerson"
  | "SupplierStaff"
  | "Client"
  | "SiteOwner"
  | "EmergencyContact"
  | "Other"
  | "Unknown";

// data types
export declare type OrganizationDetail = {
  organizationName?: string;
};

export declare type AuditDto = {
  auditName?: string;
  createdBy?: string;
  email?: string;
  lastSubmission?: string;
  status?: string;
};

export declare type TimeUpdateRequest = {
  id: number;
  jobId: number;
  transactionId: number | null;
  breakId: number | null;
  field: "start" | "end";
  start: string;
  end: string;
  user: UserDto;
  actionedBy: UserDto;
  status: "pending" | "approved" | "declined";
  createdOn: Date;
  updatedOn: Date;
};

export declare type CreateTimeUpdateRequest = {
  jobId: number;
  transactionId?: number;
  breakId?: number;
  field: "start" | "end";
  timestamp: string;
};

export declare type ActionTypeDto = "approved" | "declined";

export declare type ActionTimeUpdateRequest = {
  action: ActionTypeDto;
};

export declare type breakDto = {
  id: number;
  start: string;
  geo_start: {
    lat?: number;
    long?: number;
  };
  geo_end: {
    lat?: number;
    long?: number;
  };
  updates: TimeUpdateRequest[];
  end: string;
  user: MinimalUserDto;
  transaction: {};
  job: JobDto;
};

export declare type TransactionsDto = {
  id: number;
  user_id: number;
  in_out: string;
  action: string;
  jobId: number;
  projectId: number;
  worksiteId: number;
  updates: TimeUpdateRequest[];
  geo?: {
    lat?: number;
    long?: number;
  };
  in_timestamp: string;
  out_timestamp: string;
  in_geo: {
    lat?: number;
    long?: number;
  };
  out_geo: {
    lat?: number;
    long?: number;
  };
  supervisorName: string | null;
  supervisorSignature: FileDto;
  timestamp: string;
  job: JobDto;
  user: UserDto;
  endOfDayChecklist: EndOfDayCheckDto;
  breaks: breakDto[];
  job_type: {
    description: string;
  };
};

export declare type AttendanceDto = {
  user: UserDto;
  contact: Contact;
  createdOn: string;
  localCreatedOn: string;
  pretalkId: number;
  psscId: number;
  role: string;
  job: JobDto;
};

export declare type ListAttendanceResultDto = {
  transactions: TransactionsDto[];
  attendance: AttendanceDto[];
};

export declare type EndOfDayCheckDto = {
  id: number;
  context: string;
  additionalNotes: string;
  returningToSite: boolean;
  incidentsOnSite: boolean;
  notificationRaised: boolean;
  descriptionOfWork: string;
  createdOn?: string;
  localCreatedOn?: string;
  timeOfIncident: string;
  files: FileDto[];
  createdBy: CreatedByDto;
  jobId: number;
  jobType: {};
  version?: number;
  incidentReportCompleted?: boolean;
  incidentDetails?: string;
};

export declare type Contact = OrganizationDetail & {
  id?: number;
  organizationId?: number;
  firstName?: string;
  familyName?: string;
  lastName?: string;
  email?: string;
  jobTitle?: string;
  purpose?: string;
  companyName?: string;
  companyTradingName?: string; // optional
  companyAbn?: string; // optional
  companyAcn?: string; // optional
  companyEmail?: string;
  companyLogoUrl?: string;
  logoUrl?: string;
  organizationTradingName?: string; // optional
  organizationAbn?: string; // optional
  organizationAcn?: string; // optional
  organizationEmail?: string;
  emailAddressWork?: string;
  emailAddressPersonal?: string;
  officePhoneMain?: string; // office phone number (optional)
  officePhoneDirect?: string;
  mobilePhonePersonal?: string;
  address?: AddressData;
  mobilePhoneWork?: string;
  contactPerson?: string;
  roles?: string[];

  name?: string; // for local usage only
  internal?: boolean; // for local usage only
  departmentName?: string;
};

export declare type ClientSummary = {
  id: number;
  name: string;
};

export declare type Contractor = {
  level: number;
  contractor: Contact;
  createdOn: string;
  updatedOn: string;
  startDate: string;
  endDate: string;
  contractorFiles: FileDto[];
  status: string;
  message: string;
  role: string;
  permissions: string[];
};

export declare type StaffDto = {
  id?: number;
  photoFileId?: number;
  driverLicenseFileId?: number;
  emergencyContactId?: number;
  emergencyContact?: Contact;
  address?: AddressData;
  qualifications?: Qualification[];
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  leadingHand?: string;
  password?: string;
  role?: string;
};

/**
 * Note: There are two types of JobPermission objects.
 *
 * A minimal object will return:
 * - createSwms
 * - isOrigin
 * - isContractor
 * - isOwner
 * - transact
 * - canReview
 * - isReviewComplete
 *
 * This will be the most 'common' type returned.  The other properties will be on the 'full' type (not queried often)
 */
export declare type JobPermissionDto = {
  accepted_contractor?: boolean;
  audits?: boolean;
  canReview?: boolean;
  createSwms?: boolean;
  isReviewCompleted?: boolean;
  documents?: boolean;
  eodc?: boolean;
  notifications?: boolean;
  origin?: boolean;
  owner?: boolean;
  isOrigin?: boolean;
  isOwner?: boolean;
  isContractor?: boolean;
  pending_contractor?: boolean;
  pretalks?: boolean;
  progress?: boolean;
  pssc?: boolean;
  purchases?: boolean;
  staff?: boolean;
  swms?: boolean;
  transact?: boolean; // Can user sign in to job?
  transactions?: boolean;
};

export declare type priorityDto =
  | "urgent"
  | "high"
  | "moderate"
  | "low"
  | "none";

export declare type JobActionDto = {
  currentOrgHasAssignedStaff: false;
  currentOrgHasContractedJob: false;
  currentOrgHasCreatedChildJob: false;
};

export declare type SummaryJobDto = {
  createdOn: string;
  id: number;
  job_type: string;
  name: string;
  parentId: null | number;
  projectId?: number;
  status: string;
  updatedOn: string;
  worksite: Worksite;
};

export interface JobDto {
  id: number;
  name: string;
  additionalFiles: FileDto[];
  contractors: Contractor[];
  principalContractorRepresentatives: Contact[];
  siteOwnerRepresentatives: Contact[];
  createdByUser: Contact;
  createdOn: string;
  client: ClientSummary;
  endDate: string;
  startDate: string;
  parentId: number;
  canAssignStaff?: boolean;
  isOwner?: boolean;
  job_type?: string;
  isContractor?: boolean;
  project: ProjectDto;
  preapprovedLimit: string;
  workorderNumber: string;
  completedOn: string;
  scopeOfWorksText: string;
  scopeFile: FileDto;
  swmsFile: FileDto;
  staff: JobStaffDto[];
  status: string;
  trades: TradeDto[];
  permissions: JobPermissionDto;
  isSelfAssigned: boolean;
  worksite: Worksite;
  isCheck?: boolean;
  subRows?: JobDto[];
  actions: JobActionDto;
  priority?: priorityDto;
  updatedOn: string;
  additionalJobInstructions: string;
}

export declare type TradeClassDto = {
  id: number;
  class_name: string;
  is_custom: boolean;
  selected: boolean;
};

export declare type TradeDto = {
  trade_name: string;
  id: number;
  is_custom: boolean;
  classes: TradeClassDto[];
  selectedChild?: number | null;
  selectedClass?: number[];
  selected?: boolean;
  scopes: ScopeDto[];
};

export declare type ScopeDto = {
  scope_name: string;
  trade_id: number;
  id: number;
  class_id: number;
  is_custom: boolean;
  selected?: boolean;
  user_id: number;
  created_on: string;
  is_deleted: boolean;
  companyId: number;
  tradeClass: TradeClassDto;
  trade: TradeDto;
  workActivities: WorkActivityDto[];
  whsWorkActivities?: WHSWorkActivity[];
};

export declare type AssociateDto = {
  id: number;
  originCompany: CompanyDto;
  destinationCompany: CompanyDto;
  status: string;
  kind: string;
  message: string;
  created_on: string;
};

export declare type SearchAssociateDtoReturnType = {
  companyName: string;
  companyTradingName: string;
  companyAbn: string;
  companyAcn: string;
  companyLogoUrl: string;
  companyEmail: string;
  id: number;
};

export declare type PCDetails = {
  action: "add" | "remove";
  contractorId: number;
  contractorType: "principal";
  message?: string;
};

export declare type PCRep = {
  action: "add" | "remove";
  contactId?: number;
  contactType: "principal" | "siteOwner";
  createContact?: Contact;
};

export declare type ProjectDto = {
  [x: string]: any;
  id: number;
  name: string;
  scopeOfWorks: string;
  createdBy?: string;
  createdOn?: string;
  startDate: string;
  status?: string;
  endDate: string;
  worksiteId: number;
  worksite: Worksite;
  client: ClientDto;
  additionalFile: FileDto;
  scopeOfWorksFile: FileDto;
  contractors: Contractor[];
  principalContractorRepresentatives: Contact[];
  siteOwnerRepresentatives: Contact[];
  priority?: string;
  projectType?: string;
  isOwner?: boolean;
};

export declare type ClientDto = {
  id: number;
  name: string;
  contact?: Contact;
};

export declare type MinimalUserDto = {
  id: number;
  organizationId: number;
  organizationName?: string;
  jobTitle?: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  roles: string[];
  isChecked?: boolean;
  organization?: {
    id: number;
    company_name?: string;
  };
};

export declare type UserGroupDto = {
  id: number;
  name: string;
};

declare type jobAttachedDto = {
  createdOn: string;
  createdBy: UserDto;
  job: JobDto;
};

export declare type UserDto = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
  emergencyContact: Contact;
  managerFirstName: string;
  managerLastName: string;
  managerEmail: string;
  contact: Contact;
  status: string;
  isActive: boolean;
  jobTitle?: string;
  driverLicense: FileDto;
  jobsAttached: jobAttachedDto[];
  projectsAttached: ProjectDto[];
  photo: FileDto;
  userGroups: UserGroupDto[];
  organization: OrganizationDetail;
  organizationName?: string;
  organizationId: number;
  roles: string[];
  salt: string;
  password: string;
  createdOn: string;
};

export declare type AssignStaffItemDto =
  | {
      userId: number;
      action: "add" | "remove";
      /** When performing a 'remove' action on an assignment, we need to be certain about which instance we are removing. */
      instanceId?: number;
      /** Start Date for the employee on the job */
      startDate: string;
      /** End date for the employee on the job */
      endDate: string;
    }
  | {
      userId: number;
      action: string;
      instanceId: number;
    };

export declare type AssignStaffDto = {
  leadingHand: AssignStaffItemDto[];
  worker: AssignStaffItemDto[];
  message?: string;
  allDay?: boolean;
};

// request types
export declare type SaveCompanyRequest = {
  id: number;
  address?: AddressData;
  logoFileId?: number;
  contact?: Contact;
  discoverable?: boolean;
};

export declare type CreateProjectRequest = {
  worksiteId: number;
  name: string;
  scopeOfWorksText?: string;
  scopeOfWorksFileId?: number;
  projectDocumentsFiles?: number[];
  priority?: string;
  startDate?: string;
  endDate?: string;
};

export declare type OrgsDto = {
  organizationName: string;
  organizationTradingName: string;
  departmentName: string;
  officePhoneMain: string;
};
export declare type CreateCompanyRequest = {
  name: string;
  contact: {
    organizationName: string;
    organizationTradingName: string;
    organizationEmail: string;
    officePhoneMain: string;
    purpose: string;
  };
};

export declare type UpdateContractorRequest = {
  contractors?: PCDetails[];
  contacts?: PCRep[];
  fromSwms?: boolean;
  name?: string;
  client?: {
    clientId: number;
  };
  startDate?: string;
  endDate?: string;
  scopeOfWorksText?: string;
  scopeOfWorksFileId?: number;
};
export declare type ActionRequestId = {
  action: "add" | "remove";
  fileId: number;
};
export declare type CreateJobRequest = {
  preapprovedLimit: string;
  workorderNumber: string;
  scopeOfWorksFileId?: number;
  additionalFiles?: ActionRequestId[];
  name: string;
  projectId: number;
  parentId?: number;
  startDate: string;
  endDate: string;
  trades: number[];
  scopeOfWorksText: string;
};

export declare type updateJobRequest = {
  preapprovedLimit?: string;
  workorderNumber?: string;
  scopeOfWorksFileId?: number;
  additionalFiles?: ActionRequestId[];
  name?: string;
  projectId?: number;
  parentId?: number;
  startDate?: string;
  endDate?: string;
  trades?: number[];
  priority?: string;
  scopeOfWorksText?: string;
};

export declare type ContractJobDto = {
  message: string;
  contractorId: number;
  contractorFiles?: number[];
  permissions: string[];
  startDate?: string;
  endDate?: string;
};

export declare type CompanyDto = {
  id?: number;
  addressId?: number;
  address?: AddressData;
  companyLogo?: FileDto;
  logoUrl?: string;
  discoverable?: boolean;
  company_logo_file_id: number;
  contact?: Contact;
  created_on?: string;
};

export declare type PricingConfigDto = {
  modalEnabled: boolean;
  modalTitle: string;
  modalText: string;
  modalButtonText: string;
};

export declare type ClashRequestDto = {
  userId: number;
  startDate: string;
  endDate: string;
};

export declare type SwmsPersonDto = {
  contact?: Contact;
  user?: UserProfile;
  role: string;
};

export declare type SwmsSignatureDto = {
  user: UserProfile;
  signatureFile: FileDto;
  createdOn: string;
  kind: string;
};

export declare type SwmsSdsMsds = {
  id: number;
  path: string;
  createdOn: Date;
};

export declare type SwmsRecipientDto = {
  userId?: number;
  companyId?: number;
  contactId?: number;
  action: "add" | "remove";
  role?: string;
};

export declare type HighRiskDto = {
  id: number;
  name: string;
  status: string;
  description: string;

  // This is only exposed for the client side benefit.  Not present in the DB.
  selected: boolean;
};

export declare type PotentialHazardDto = {
  id?: number;
  work_activity_id?: number;
  // workActivity: WorkActivityDto

  potential_hazard?: string;
  risk_score?: string;
  control_measures?: string;
  person_responsible?: string;
  created_on?: Date;
  risk_score_control?: string;
  kind?: string;
};

export declare type WorkActivityDto = {
  id: number;
  name?: string;
  user_id: number;
  created_on?: Date;
  description: string;
  group: string;
  is_deleted?: boolean;
  companyId?: number;
  is_selected?: boolean;

  /**
   * ID of object that supersedes this one (ie: the new version of the WA)
   */
  supersededId?: number;
  potential_hazards?: PotentialHazardDto[];
  highRisks?: HighRiskDto[];
  kind?: string;
};
export declare type SwmsDto = {
  id: number;
  isWhsManagementPlan: boolean;
  status: string;
  step: string;
  createdOn: Date;
  sds_msds: SwmsSdsMsds[];
  signatures: SwmsSignatureDto[];
  subcontractors: CompanyDto[];
  persons: SwmsPersonDto[];
  scopes: TradeDto[];
  workActivities: WorkActivityDto[];
  file: FileDto;
  highRisks: HighRiskDto[];
  job: JobDto;
  jobId: number;
  user: UserProfile;
  userId: number;
  company: CompanyDto;
  companyId: number;
  kind: string;
};

export declare type JobsPretalkSignatureDto = {
  contact: Contact;
  user: UserDto;
  file: FileDto;
  createdOn: string;
};

export declare type JobsPretalkDto = {
  id: number;
  job: JobDto;
  inductor: MinimalUserDto;
  audioFile: FileDto;
  createdOn: string;
  localCreatedOn: string;
  signatures: JobsPretalkSignatureDto[];
  isSigned: boolean;
  files: FileDto[];
  attendance: AttendanceDto[];
  notesAudioFile: FileDto;
};

export declare type CreateWorkActivityRequest = {
  description?: string;
  group?: string;
  high_risks?: number[];
  potentialHazards?: AddPotentialHazard[];
  public?: boolean;
};

export declare type PotentialHazardTypes = "add" | "remove" | "update";

export declare type AddPotentialHazard = {
  id?: number;
  action?: PotentialHazardTypes;
  potentialHazard?: PotentialHazardDto;
};

/**
 * UpdateSwmsDto will be used for submitting all the rest of the SWMS data throughout the process.
 *
 */
export declare type UpdateSwmsDto = {
  recipients: SwmsRecipientDto[];
  step: string;
  scopes: number[];
  workActivities: number[];
  sds_msds: string[];
};

export declare type CreateSwmsDto = {
  isWhsManagementPlan: boolean;
  jobId: number;
  step: SwmsStepsType;
};

export declare type SwmsStepsType =
  | "company_details"
  | "client_details"
  | "site_details"
  | "project_details"
  | "employees"
  | "trades"
  | "work_activities"
  | "sds_msds"
  | "confirm_details"
  | "generated";

export declare type WorkActivityType = "full" | "minimal";

/**
 * Sign SWMS Dto is under consideration currently due to requirements for visitors to be able to sign the documents.
 * Visitors will be signing using some method of a pregenerated URL, and submitting a Swms ID/File ID
 */
export class SignSwmsDto {}

// response types
export declare type FileDto = {
  fileType?: string;
  url?: string;
  inlineUrl?: string;
  id?: number;
  userId?: number;
  name?: string;
  path?: string;
  mime?: string;
  size?: number;
  meta?: string;
  linkExpiry?: string;
  created_on?: string;
  transcript?: TranscriptDto | string;
};

export declare type TranscriptDto = {
  fileId: number;
  jobName: string;
  raw: string;
  status: "COMPLETED" | "PENDING" | "IN_PROGRESS";
  text: string;
};

export declare type Worksite = {
  formattedAddress: string;
  id: number;
  address: AddressData;
  name: string;
  company: Contact;
  createdBy?: string;
  createdOn?: string;
  status?: string;
  order?: string;
};

export declare type DocUploadDto = {
  files?: FileDto;
  permission?: DocPermissionDto;
};

export declare type ResetPasswordDto = {
  firstName: string;
  lastName: string;
  email: string;
  resetPasswordToken: string;
};

export declare type ForgotPasswordReq = {
  email?: string;
  id?: string;
};

export declare type ResetPasswordReq = {
  password?: string;
  confirmPassword?: string;
  resetPasswordToken?: string;
};

export declare type ChangePasswordReq = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export declare type UserDetailRes = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  accountSetupToken?: string;
};

export declare type Qualification = {
  id: number;
  name: string;
  companyId: number;
  is_private: boolean;
  createdOn: string;
  kind: string;
  lmsCourseUrl: string;
  lmsIntegrated: boolean;
  lmsMainActivityId: string;
  // for local usage. This won't be in API.
  selected: boolean;
  fileId: number;
  file?: FileDto;
  defaultSelected?: UserQualification;
  expiryDate: string;
};

export declare type UserQualification = {
  id: number;
  expiryDate: string;
  photo: FileDto;
  qualification: Qualification;
};
export declare type JobDocumentDto = {
  id: number;
  file: FileDto;
  signatureRequired: boolean;
  terms: string;
  allJobsInProjectCanRead: boolean;
  user: UserDto;
  jobId?: number;
  projectId?: number;
  isSigned?: boolean;
  canShare?: boolean;
  createdOn: string;
  signatures: JobDocumentSignatureDto[];
};

export declare type JobDocumentAuditDto = {
  id: number;
  jobDocument: JobDocumentDto;
  signature: JobDocumentSignatureDto;
  user: UserDto;
  contact: Contact;
  action: any;
  createdOn: string;
};

export declare type CreateShareDocumentRequestResultDto = {
  id: string;
  email: string;
};

export declare type JobDocumentSignatureDto = {
  id: number;
  user: UserDto;
  file: FileDto;
  jobDocument: JobDocumentDto;
  termsAccepted: boolean;
  terms: string;
  contact?: Contact;
  kind: string;
};
export declare type JobDocumentUpdateRequest = {
  jobDocumentId: number;
  action: "rename" | "delete";
  fileName?: string;
};

export declare type DocDto = {
  file: File;
  permission: DocPermissionDto;
};

export declare type DocPermissionDto = {
  signatureRequired: boolean;
  terms: string;
  allJobsInProjectCanRead: boolean;
  allowRecipientToShare: boolean;
};
export declare type CreateDocumentDto = {
  fileId: number;
  jobId?: number;
  projectId?: number;
  signatureRequired: boolean;
  terms: string;
  allJobsInProjectCanRead: boolean;
  allowRecipientToShare: boolean;
  userGroupsPermitted: string[];
};
export declare type CreateShareDocumentRequestDto = {
  documents: number[];
  additionalNotes?: string;
  contacts: number[];
};
export declare type ShareDocumentDto = {
  id: string;
  documents?: JobDocumentDto[];
  additionalNotes?: string;
  project?: ShareDocumentMinimalJobProjectDto;
  contact?: Contact;
  worksite?: Worksite;
  job?: ShareDocumentMinimalJobProjectDto;
};
export declare type ShareDocumentMinimalJobProjectDto = {
  id: number;
  name?: string;
};
export declare type SignJobDocumentDto = {
  jobDocumentId: number;
  terms?: string;
  fileId: number;
};
export declare type CreateDocumentAuditDto = {
  jobDocumentId?: number;
  action?: "SIGNATURE_DECLINE" | "OPEN";
};
export declare type CreateWorksiteDto = {
  name?: string;
  address?: AddressData;
};
export declare type JobContractOfferDto = {
  job?: JobDto;
  status?: string;
  message: string;
  updatedOn: string;
  createdOn: string;
  contractorFiles: FileDto[];
  notificationId?: number;
  permissions?: string[];
  requestor?: CompanyDto;
};

export declare type ValidJobStatus = {
  options: string[];
};

export declare type ProgressDto = {
  id: number;
  user: UserDto;
  job: JobDto;
  location: string;
  shortDescription: string;
  workDescription: string;
  issueDescription: string;
  variations: boolean;
  createdOn: string;
  localCreatedOn?: string;
  files: FileDto[];
};

export declare type SubscriptionDetailCountDto = {
  used: number;
  purchased: number;
  remaining: number;
};
export declare type SubscriptionDetailDto = {
  pah: SubscriptionDetailCountDto;
  sah: SubscriptionDetailCountDto;
  nah: SubscriptionDetailCountDto;
};

export declare type checkListItem = {
  prompt: string;
  result: boolean;
};

export declare type InspectionChecklistItemType = {
  prefix: string;
  name: string;
  items: checkListItem[];
  mandatory: boolean;
};
export declare type PrestartSafetyChecklistDto = {
  id: number;
  createdOn: string;
  transactionId: number;
  jobId: number;
  attendance: AttendanceDto[];
  jobSafetyChecklist: InspectionChecklistItemType[];
  completedSafetyInduction: boolean;
  obligationsUnderstood: boolean;
  haveRequiredPPE: boolean;
  presentedPretalk: boolean;
  presentPretalk: boolean;
  inspectionComplete: boolean;
  siteHazardsPresent: boolean;
  additionalSafetyNotes: string;
  hazardManageText: string;
  createdBy: CreatedByDto;
  files: FileDto[];
  displaySigns: boolean;
  ppeRequired: boolean;
  ppeIssued: boolean;
  proposedWorks: string;
  preparedSwms: boolean;
  miscNotes: string;
  localCreatedOn: string;
  context: string;
};
export declare type CreatedByDto = {
  id: number;
  ownerId: string;
  email: string;
  updatedEmail: string;
  firstName: string;
  lastName: string;
  companyId: number;
  twoFactorEnabled: boolean;
  twoFactorSecret: string;
  termsOfService: boolean;
  privacyPolicy: boolean;
  abn: string;
  phone: string;
  ipAddress: string;
  country: string;
  address1: string;
  address2: string;
  suburb: string;
  state: string;
  postcode: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
  leadingHand: boolean;
  employeeIdForSync: string;
  pp_pah: number;
  pp_sah: number;
  pp_nah: number;
  activation_code: string;
  forgotten_password_code: string;
  forgotten_password_time: string;
  status: string;
  isActive: boolean;
  notification_settings: string;
  discount_code: string;
  capacity: number;
  customerId: string;
  createdOn: Date;
  driver_license_file_id: number;
  photo_file_id: string;
  contactId: number;
  emergencyContactId: number;
  organizationId: number;
  accountSetupToken: string;
  resetPasswordToken: string;
  kind: string;
  userGroups: any[];
  projectsAttached: any[];
  jobsAttached: any[];
  organizationName: string;
  roles: any[];
};

export declare type classDto = {
  id: number;
  class_name: string;
  is_custom: boolean;
};

export declare type TradeCreateTradeDto = {
  trade_name: string;
  class_ids: Number[];
  isPublic?: boolean;
};

export declare type createTradeDto = {
  name: string;
};

export declare type updateTradeDto = {
  name: string;
  derivedScopes: number[];
  isPublic: boolean;
};

export declare type TradePopulate = {
  swmsId: number;
  tradeId: number;
  workActivities: number[];
};

export declare type TradePopulateV4 = {
  swmsId: number;
  workActivities: number[];
};

export declare type TradeCreateScopeDto = {
  name: string;
  classId: number;
  tradeId: number;
  workActivities?: number[];
};

export declare type TradeCreateScopeDtoV4 = {
  name?: string;
  classId: number;
  tradeId: number;
  derivedScopeId?: number;
  workActivities?: number[];
};

export declare type UpdateWorkActivityDtoV4 = {
  action: "add" | "remove";
  workActivityId: number;
};

export declare type TradeUpdateScopeDtoV4 = {
  id: number;
  classId?: number;
  isPublic?: boolean;
  name?: string;
  workActivities?: UpdateWorkActivityDtoV4[];
};

export declare type NoteDto = {
  id: number;
  job: JobDto;
  project: ProjectDto;
  notification: NotificationDto;
  text: string;
  file: FileDto;
  noteType: string;
  createdBy: UserDto;
  createdOn: string;
  canBeDeleted: boolean;
};

export declare type JobStaffDto = {
  id: number;
  role: string;
  acceptance: string;
  job?: JobDto;
  startDate: string;
  transactionState: "pending" | "in" | "out";
  endDate: string;
  message: string;
  active: boolean;
  user: MinimalUserDto;
  createdOn: string;
  updatedOn: string;
};

export declare type JobStaffRequestDto = {
  action: "reject" | "accept";
};

export declare type ReviewBodyDto = {
  noteBody?: string;
  status: "accepted" | "declined";
};

export declare type InductionStatus =
  | "complete"
  | "incomplete"
  | "expired"
  | "not_started";

export declare type InductionDto = {
  id: number;
  name: string;
  companyId: number;
  status: InductionStatus;
  lmsIntegrated: boolean;
  lmsRegistrationUuid: string;
  lmsUrl: string;
  createdOn: string;
  completedDate: string;
  expiryDate: string;
};

export declare type EnrolBodyDto = {
  registrationId: string;
  qualificationId: number;
};

export declare type VariationSignatureDto = {
  user?: UserDto;
  contact?: Contact;
  signatureFile: FileDto;
  type: "creator" | "recipient";
  firstName: string;
  familyName: string;
  organizationName: string;
  createdOn: string;
  kind: string;
};

export declare type CreateVariationDto = {
  name: string;
  jobId: number;
  variationNumber?: string;
  siteInstructionNumber?: string;
  scope?: string;
  additionalNotes?: string;
  pricingType: "flat_rate" | "hourly" | "agreed_contract_rate";
  price: number;
  files: number[];
};

export declare type VariationStatusDto =
  | "draft"
  | "pending"
  | "accepted"
  | "rejected";

export interface VariationDto {
  id: number;
  name: string;
  status: VariationStatusDto;
  variationNumber?: string;
  siteInstructionNumber?: string;
  scope?: string;
  jobId: number;
  worksiteId?: number;
  projectId?: number;
  jobName: string;
  additionalNotes?: string;
  files: FileDto[];
  pricingType: "flat_rate" | "hourly" | "agreed_contract_rate";
  price: number;
  createdOn: string;
  updatedOn: string;
  createdBy: UserDto;
  signatures: VariationSignatureDto[];
  notes: NoteDto[];
}

export declare type SignVariationDto = {
  variationId: number;
  type: "creator" | "recipient";
  fileId: number;
  contactId?: number;
  signingRequestId?: string;
};

export declare type RejectVariationRequestDto = {
  signingRequestId: string;
  action: string;
};

export declare type CreateVariationNoteDto = {
  variationId: number;
  text: string;
  noteType: string;
  firstName: string;
  familyName: string;
};

export declare type SignatureRequests = {
  contact: Contact;
  isEmailed: boolean;
  isSigned: boolean;
  createdOn: string;
};

export interface VariationSignedRequestDto extends VariationDto {
  signingRequests: SignatureRequests[];
}

export declare type VariationDetailsDto = {
  id: string;
  variation: VariationDto;
  contact: Contact;
};

export const DefectPricingTypeArray = [
  "flat_rate",
  "hourly",
  "no_cost",
] as const;
export type DefectPricingType = (typeof DefectPricingTypeArray)[number];

export declare type DefectSignatureDto = {
  user?: UserDto;
  contact?: Contact;
  signatureFile: FileDto;
  type: "creator" | "recipient";
  firstName: string;
  familyName: string;
  organizationName: string;
  createdOn: string;
  kind: string;
};

export declare type CreateDefectDto = {
  name: string;
  jobId: number;
  defectNumber?: string;
  details?: string;
  worksRequired?: string;
  pricingType: DefectPricingType;
  price: number;
  files: number[];
};

export type DefectStatusDto =
  | "draft"
  | "pending"
  | "accepted"
  | "rejected"
  | "cancelled";

export interface DefectDto {
  id: number;
  name: string;
  worksiteId: number;
  projectId: number;
  jobId: number;
  jobName: string;
  defectNumber?: string;
  details?: string;
  status: DefectStatusDto;
  worksRequired?: string;
  files: FileDto[];
  pricingType: DefectPricingType;
  price: number;
  createdOn: Date;
  updatedOn: Date;
  createdBy?: UserDto;
  signatures: DefectSignatureDto[];
  notes: NoteDto[];
}

export declare type SignDefectDto = {
  defectId: number;
  type: "creator" | "recipient";
  fileId: number;
  contactId?: number;
  signingRequestId?: string;
};

export declare type RejectDefectRequestDto = {
  defectId?: number;
  signingRequestId: string;
  action: "reject";
};

export declare type CreateDefectNoteDto = {
  defectId: number;
  text: string;
  noteType: string;
  firstName: string;
  familyName: string;
};

export interface DefectSignedRequestDto extends DefectDto {
  signingRequests: SignatureRequests[];
}

export declare type DefectDetailsDto = {
  id: string;
  defect: DefectDto;
  contact: Contact;
};

export declare type ScheduleResponseParams = {
  view: "day" | "week" | "month";
  jobs: number[];
  projects: number[];
  worksites: number[];
  status: string[];
  jobTypes: string[];
  contractors: number[];
  employees: number[];
  startDate: string;
  endDate: string;
};

export interface SchedulerTimelinePropsDto {
  StartTime: string;
  EndTime: string;
  Id: number;
  Guid: string;
  IsAllDay: boolean;
  data: {
    count: number;
    isLeft: boolean;
    isRight: boolean;
    StartTime: string;
    EndTime: string;
    trimStartTime: string;
    trimEndTime: string;
  };
  Index: 0;
}

export interface AccountFeatureDto {
  canToggle: boolean;
  createdOn: string;
  enabled: boolean;
  purchased: boolean;
  feature: string;
  role: "SAH" | "PAH" | "NAH";
  children: string[];
  updatedOn: string;
}

export interface UpdateAccountFeatureDto {
  role: "SAH" | "PAH" | "NAH";
  feature: string;
  enabled: boolean;
}

export interface TimeSheetPayload {
  startDate: string;
  endDate: string;
  jobTypes: Array<"make_safe" | "repair_work" | "standard">;
}

export declare type StaffEventPropsDto = SchedulerTimelinePropsDto &
  JobStaffDto;

export default {
  //#region New APIs
  contactUs: (data) => doFetch("/api/v3/mail/contactUs", "POST", data),
  //#region Files api
  uploadFile: (data: BodyInit): Promise<FileDto> =>
    doFetch(`/api/v3/files`, "POST", data),
  uploadSWMSNoAuthFile: (id: string, data: BodyInit): Promise<FileDto> =>
    doFetch(`/api/v3/files/swms/${id}`, "POST", data),
  uploadDocumentNoAuthFile: (id: string, data: BodyInit): Promise<FileDto> =>
    doFetch(`/api/v3/files/documents/${id}`, "POST", data),
  uploadVariationRequestFile: (
    variationUUID: string,
    data: BodyInit
  ): Promise<FileDto> =>
    doFetch(`/api/v3/files/variations/${variationUUID}`, "POST", data),
  uploadDefectRequestFile: (
    defectUUID: string,
    data: BodyInit
  ): Promise<FileDto> =>
    doFetch(`/api/v3/files/defects/${defectUUID}`, "POST", data),
  shortFile: (id: number): Promise<{ shortUrl: string }> =>
    doFetch(`/api/v3/files/short/${id}`, "POST"),
  getFileById: (id: number): Promise<FileDto> =>
    doFetch(`/api/v3/files/${id}`, "GET"),

  //#endregion

  //#region forgotPassword

  forgotPasswordApi: (data: ForgotPasswordReq): Promise<{ success: boolean }> =>
    doFetch(`/api/v3/auth/reset`, "POST", data),

  getResetUserDetail: (id: string): Promise<ResetPasswordDto> =>
    doFetch(`/api/v3/auth/reset/${id}`, "GET"),
  setResetPassword: (data: ResetPasswordReq): Promise<UserDto> =>
    doFetch(`/api/v3/auth/reset`, "PUT", data),
  changePassword: (data: ChangePasswordReq) =>
    doFetch(`/api/v3/auth/change`, "PUT", data),

  //#endregion

  //#impersonate user

  ImpersonateUser: (data: { email: string }): Promise<AuthData> =>
    doFetch(`/api/v3/auth/impersonate`, "POST", data),

  //#end impersonate user

  //#region userSetup

  getUserSetup: (id: string): Promise<UserDetailRes> =>
    doFetch(`/api/v3/auth/setup/${id}`, "GET"),

  setUserSetup: (data: SetupAccount): Promise<UserDto> =>
    doFetch(`/api/v3/auth/setup/`, "POST", data),
  //#endregion

  //#region Qualification apis
  qualifications: (): Promise<Qualification[]> =>
    doFetch(`/api/v3/qualification`),
  addQualification: (name: string) =>
    doFetch(`/api/v3/qualification`, "POST", { name }),
  userQualifications: (id: number): Promise<UserQualification[]> =>
    doFetch(`/api/v3/qualification/user/${id}`),
  //#endregion

  //#region user APIs
  updateUser: (id: number, data: RegisterStaffRequest): Promise<UserDto> =>
    doFetch(`/api/v3/user/${id}`, "PUT", data),
  getCount: (): Promise<SubscriptionDetailDto> =>
    doFetch(`/api/v3/user/count`, "GET"),

  //#end-region

  //#region Client APIs
  createClientByContactId: (data: { contactId: number }): Promise<ClientDto> =>
    doFetch(`/api/v3/client`, "POST", data),
  clientAutocomplete: (data: { search: string }): Promise<ClientSummary[]> =>
    doFetch(`/api/v3/client/autocomplete`, "POST", data),
  updateClient: (
    id: number,
    data: { name: string; contact: Contact }
  ): Promise<ClientDto> => doFetch(`/api/v3/client/${id}`, "PUT", data),

  getClients: (): Promise<ClientDto[]> =>
    doFetch(`/api/v3/client/?full=true`, "GET"),

  clientById: (id: number): Promise<ClientDto> =>
    doFetch(`/api/v3/client/${id}`, "GET"),
  //#endregion

  //#region Contact APIs
  contactAutocomplete: (data: {
    search: string;
    purpose?: string;
  }): Promise<Contact[]> =>
    doFetch(
      `/api/v3/contact/autocomplete${
        data.purpose ? `?purpose=${data.purpose}` : ""
      }`,
      "POST",
      { search: data.search }
    ),
  contactEdit: (id: number, data: Contact): Promise<Contact> =>
    doFetch(`/api/v3/contact/${id}`, "PUT", data),
  addContact: (data: Contact): Promise<Contact> =>
    doFetch(`/api/v3/contact`, "POST", data),
  userById: (id: number): Promise<UserDto> =>
    doFetch(`/api/v3/user/${id}`, "GET"),
  getContactById: (id: number): Promise<Contact> =>
    doFetch(`/api/v3/contact/${id}`, "GET"),
  getContact: (purpose: ContactPurpose): Promise<Contact[]> =>
    doFetch(`/api/v3/contact/?purpose=${purpose}`),
  deleteContact: (id: number) => doFetch(`/api/v3/contact/${id}`, "DELETE"),
  getAllOrgs: (): Promise<OrgsDto[]> => doFetch(`/api/v3/contact/orgs`, "GET"),

  getContacrOrgs: () => doFetch(`/api/v3/contact/orgs`),

  //#endregion

  //#region WorkActivity APIs
  getWorkActivity: (type: WorkActivityType): Promise<WorkActivityDto[]> =>
    doFetch(`/api/v3/workactivities?type=${type}`, "GET"),

  addWorkActivity: (
    data: CreateWorkActivityRequest
  ): Promise<WorkActivityDto> =>
    doFetch(`/api/v3/workactivities`, "POST", data),

  updateWorkActivity: (
    data: CreateWorkActivityRequest,
    id: number
  ): Promise<WorkActivityDto> =>
    doFetch(`/api/v3/workactivities/${id}`, "PUT", data),

  deleteWorkActivity: (id: number) =>
    doFetch(`/api/v3/workactivities/${id}`, "DELETE"),

  acceptWorkActivity: (id: string) =>
    doFetch(`/api/v3/work-request/${id}`, "GET"),

  //#endregion

  //#region Company Apis
  companyAutocomplete: (data: {
    companyName: string;
    myOrganizationOnly?: boolean;
  }): Promise<Contact[]> =>
    doFetch(`/api/v3/company/autocomplete`, "POST", {
      search: data.companyName,
      myOrganizationOnly: data.myOrganizationOnly ?? false,
    }),
  saveCompanyDetails: (data: SaveCompanyRequest) =>
    doFetch(`/api/v3/company`, "PUT", data),
  companyDetails: (id: number): Promise<CompanyDto> =>
    doFetch(`/api/v3/company/${id}`, "GET"),
  createCompany: (data: CreateCompanyRequest): Promise<CompanyDto> =>
    doFetch(`/api/v3/company`, "POST", data),
  //#endregion

  //#region Associate
  companyByEmail: (data: {
    companyEmail: string;
  }): Promise<SearchAssociateDtoReturnType[]> =>
    doFetch(`/api/v3/associate/search`, "POST", {
      companyEmail: data.companyEmail,
    }),
  associateByName: (data: {
    companyName: string;
  }): Promise<SearchAssociateDtoReturnType[]> =>
    doFetch(`/api/v3/associate/search`, "POST", {
      companyName: data.companyName,
    }),
  sendContractorInvitation: (data: { companyId: number; message: string }) =>
    doFetch(`/api/v3/associate`, "POST", data),
  associateList: (): Promise<AssociateDto[]> =>
    doFetch(`/api/v3/associate`, "GET"),
  associateConnectedList: (): Promise<CompanyDto[]> =>
    doFetch(`/api/v3/associate/list`, "GET"),
  acceptAssociate: (associateId: number) =>
    doFetch(`/api/v3/associate/accept/${associateId}`, "POST"),
  deleteAssociate: (associateId: number) =>
    doFetch(`/api/v3/associate/${associateId}`, "DELETE"),
  //#endregion

  //#region Worksite APIs
  registerWorksite: (data: CreateWorksiteDto): Promise<Worksite> =>
    doFetch("/api/v3/worksite", "POST", data),
  getAllWorksites: (queryParams?: URLSearchParams) =>
    doFetch(`/api/v4/worksite/${queryParams ? `?${queryParams}` : ""}`, "GET"),
  getWorksiteData: (id: number): Promise<Worksite> =>
    doFetch(`/api/v3/worksite/${id}`, "GET"),
  editWorksites: (id: number, data: CreateWorksiteDto): Promise<Worksite> =>
    doFetch(`/api/v3/worksite/${id}`, "PUT", data),
  //#endregion

  //#region Project APIs
  createProject: (data: CreateProjectRequest): Promise<ProjectDto> =>
    doFetch("/api/v3/project", "POST", data),
  updateContractors: (projectId: number, data: UpdateContractorRequest) =>
    doFetch(`/api/v3/project/${projectId}`, "PUT", data),
  projectList: (): Promise<ProjectDto[]> => doFetch("/api/v3/project", "GET"),
  worksiteProjectList: (worksiteId: number): Promise<ProjectDto> =>
    doFetch(`/api/v3/project/${worksiteId}`, "GET"),
  getProjectJobs: (projectId: number): Promise<JobDto[]> =>
    doFetch(`/api/v3/project/${projectId}/jobs`, "GET"),
  getProjectJobsSummary: (projectId: number): Promise<SummaryJobDto[]> =>
    doFetch(`/api/v3/project/${projectId}/jobs/summary`, "GET"),
  getNotificationJobs: (jobId: number): Promise<SummaryJobDto[]> =>
    doFetch(`/api/v3/job/${jobId}/related`, "GET"),
  getProjectById: (projectId: number): Promise<ProjectDto> =>
    doFetch(`/api/v3/project/${projectId}`, "GET"),
  //#endregion

  //#region Jobs APIs
  assignedJob: (): Promise<JobDto[]> => doFetch(`/api/v3/job/assigned`),
  createJob: (data: CreateJobRequest): Promise<JobDto> =>
    doFetch("/api/v3/job", "POST", data),
  updateJobDetail: (jobId: number, data: updateJobRequest): Promise<JobDto> =>
    doFetch(`/api/v3/job/${jobId}`, "PUT", data),
  contractorJob: (jobId: number, data: ContractJobDto): Promise<JobDto> =>
    doFetch(`/api/v3/job/${jobId}/contract`, "POST", data),
  jobDetail: (jobId: number): Promise<JobDto> =>
    doFetch(`/api/v3/job/${jobId}`, "GET"),
  getJobStaffDetail: (jobId: number): Promise<JobStaffDto[]> =>
    doFetch(`/api/v3/job/${jobId}/staff`, "GET"),
  contractList: (jobId: number) =>
    doFetch(`/api/v3/job/${jobId}/contract`, "GET"),
  getAllJob: (): Promise<JobDto[]> => doFetch("/api/v3/job", "GET"),
  getProjectByJob: (jobId: number): Promise<ProjectDto> =>
    doFetch(`/api/v3/job/${jobId}/project`, "GET"),
  issueSelf: (jobId: number, data: any = {}): Promise<JobDto[]> =>
    doFetch(`/api/v3/job/${jobId}/issue_self`, "POST", data),
  assignSelfWithStaff: (jobId: number, data: AssignStaffDto): Promise<JobDto> =>
    doFetch(`/api/v3/job/${jobId}/assign_staff`, "POST", data),
  checkClash: (
    jobId: number,
    data: ClashRequestDto
  ): Promise<{ clash: boolean }> =>
    doFetch(`/api/v3/job/${jobId}/clash`, "POST", data),
  getJobOffers: (): Promise<JobContractOfferDto[]> =>
    doFetch(`/api/v3/job/offers`, "GET"),
  getJobOffersById: (id: number): Promise<JobContractOfferDto[]> =>
    doFetch(`/api/v3/job/${id}/offers`, "GET"),
  getJobOffersByNotificationId: (id: number): Promise<JobContractOfferDto[]> =>
    doFetch(`/api/v3/notification/${id}/offers`, "GET"),
  contractorAccept: (id: number) =>
    doFetch(`/api/v3/job/${id}/contractor_accept`, "POST"),

  assignedWorkAcceptOrReject: (id: number, data: JobStaffRequestDto) =>
    doFetch(`/api/v3/job/${id}/staff`, "POST", data),

  contractorReject: (id: number) =>
    doFetch(`/api/v3/job/${id}/contractor_reject`, "POST"),
  getJobStatus: (id: number): Promise<ValidJobStatus> =>
    doFetch(`/api/v3/job/${id}/status`, "GET"),
  updateJobStatus: (data, id: number): Promise<JobDto> =>
    doFetch(`/api/v3/job/${id}/status`, "PUT", data),
  requestStatusUpdate: (data, id: number): Promise<JobDto> =>
    doFetch(`/api/v3/job/${id}/status_request`, "POST", data),
  reviewJob: (id: number, reviewBody: ReviewBodyDto): Promise<JobDto> =>
    doFetch(`/api/v3/job/${id}/review`, "POST", reviewBody),

  //#endregion

  //#region Documents
  getDocumentsByJob: (jobIds: string): Promise<JobDocumentDto[]> =>
    doFetch(`/api/v3/documents/jobs?ids=${jobIds}`, "GET"),
  jobDocuments: (jobId: number): Promise<JobDocumentDto[]> =>
    doFetch(`/api/v3/documents/job/${jobId}`, "GET"),
  projectDocument: (projectId: number): Promise<JobDocumentDto[]> =>
    doFetch(`/api/v3/documents/project/${projectId}`, "GET"),
  updateDocument: (data: JobDocumentUpdateRequest): Promise<JobDocumentDto> =>
    doFetch(`/api/v3/documents`, "PUT", data),
  createDocument: (data: CreateDocumentDto): Promise<JobDocumentDto> =>
    doFetch(`/api/v3/documents`, "POST", data),
  shareDocument: (
    data: CreateShareDocumentRequestDto
  ): Promise<CreateShareDocumentRequestResultDto> =>
    doFetch(`/api/v3/documents/share`, "POST", data),
  getShareDocument: (id: string): Promise<ShareDocumentDto> =>
    doFetch(`/api/v3/documents/share/${id}`, "GET"),
  signShareDocument: (
    id: string,
    data: SignJobDocumentDto
  ): Promise<JobDocumentSignatureDto> =>
    doFetch(`/api/v3/documents/share/${id}/sign`, "POST", data),
  signDocument: (
    id: string,
    data: SignJobDocumentDto
  ): Promise<JobDocumentSignatureDto> =>
    doFetch(`/api/v3/documents/${id}/sign`, "POST", data),
  auditDocument: (
    id: string,
    data: CreateDocumentAuditDto
  ): Promise<JobDocumentAuditDto> =>
    doFetch(`/api/v3/documents/${id}/audit`, "POST", data),
  auditShareDocument: (
    id: string,
    data: CreateDocumentAuditDto
  ): Promise<JobDocumentAuditDto> =>
    doFetch(`/api/v3/documents/share/${id}/audit`, "POST", data),
  allSignature: (id: number): Promise<JobDocumentSignatureDto[]> =>
    doFetch(`/api/v3/documents/${id}/signatures`, "GET"),
  allShareSignature: (
    shareId: string,
    id: number
  ): Promise<JobDocumentSignatureDto[]> =>
    doFetch(`/api/v3/documents/share/${shareId}/${id}/signatures`, "GET"),

  //#endregion

  //#price config start

  priceConfig: () => doFetch(`/api/v3/config`, "GET"),

  //#price config start

  //#region Job List Associated
  purchasesRecord: (jobId: number): Promise<PurchasesRecordDto[]> =>
    doFetch(`/api/v3/purchase/job/${jobId}`, "GET"),
  stockPurchasesRecord: (): Promise<PurchasesRecordDto[]> =>
    doFetch(`/api/v3/purchase`, "GET"),
  purchasesRecordJobs: (jobIds: string): Promise<PurchasesRecordDto[]> =>
    doFetch(`/api/v3/purchase/jobs?ids=${jobIds}`, "GET"),
  whsManagement: (jobId: number): Promise<SwmsDto[]> =>
    doFetch(`/api/v3/swms/job/${jobId}/all`, "GET"),
  whsManagementJobs: (jobIds: string): Promise<SwmsDto[]> =>
    doFetch(`/api/v3/swms/jobs/all?ids=${jobIds}`, "GET"),
  progressReport: (jobId: number): Promise<ProgressDto[]> =>
    doFetch(`/api/v3/job/${jobId}/progress`, "GET"),
  progressReportJobs: (jobIds: string): Promise<ProgressDto[]> =>
    doFetch(`/api/v3/job/progress?ids=${jobIds}`, "GET"),
  deleteSWMS: (swmsId: number) => doFetch(`/api/v3/swms/${swmsId}`, "DELETE"),

  //#endregion

  //#region Prestart Safety Check
  getPrestartSafetyChecksJob: (
    jobIds: string
  ): Promise<PrestartSafetyChecklistDto[]> =>
    doFetch(`/api/v3/prestart/sc/jobs?ids=${jobIds}`, "GET"),
  getPrestartSafetyCheck: (
    jobId: number
  ): Promise<PrestartSafetyChecklistDto[]> =>
    doFetch(`/api/v3/prestart/sc/job/${jobId}`, "GET"),

  //#endregion

  //#region Prestart Talk
  preStartTalk: (jobId: number): Promise<JobsPretalkDto[]> =>
    doFetch(`/api/v3/prestart/talk/job/${jobId}`, "GET"),
  preStartTalkJobs: (jobIds: string): Promise<JobsPretalkDto[]> =>
    doFetch(`/api/v3/prestart/talk/jobs?ids=${jobIds}`, "GET"),
  //#endregion

  //#region Prestart End Of Day CheckList
  getEndOfDayCheck: (jobId: number): Promise<EndOfDayCheckDto[]> =>
    doFetch(`/api/v3/prestart/eodc/job/${jobId}`, "GET"),
  getMultipleEndOfDayCheck: (jobIds: string): Promise<EndOfDayCheckDto[]> =>
    doFetch(`/api/v3/prestart/eodc/jobs?ids=${jobIds}`),
  //#endregion

  //#region worksite variation
  getVariationByUser: (): Promise<VariationSignedRequestDto[]> =>
    doFetch(`/api/v3/variations/user`, "GET"),
  getVariationById: (variationId: number): Promise<VariationSignedRequestDto> =>
    doFetch(`/api/v3/variations/${variationId}`, "GET"),
  createVariation: (payload: CreateVariationDto) =>
    doFetch(`/api/v3/variations`, "POST", payload),
  sendVariation: (payload: { variationId: number; contactId: number }) =>
    doFetch(`/api/v3/variations/send`, "POST", payload),
  getVariationByUUID: (uuid: string): Promise<VariationDetailsDto> =>
    doFetch(`/api/v3/variations/sign/${uuid}`, "GET"),
  signVariation: (payload: SignVariationDto) =>
    doFetch(`/api/v3/variations/sign`, "POST", payload),
  signVariationRequest: (payload: SignVariationDto) =>
    doFetch(`/api/v3/variations/sign/request`, "POST", payload),
  rejectVariationRequest: (payload: RejectVariationRequestDto) =>
    doFetch(`/api/v3/variations/sign/reject`, "POST", payload),
  postVariationNote: (payload: CreateVariationNoteDto) =>
    doFetch(`/api/v3/note`, "POST", payload),
  getVariationsNotes: (variationId: number) =>
    doFetch(`/api/v3/note/variation/${variationId}`, "GET"),
  getVariationByJobs: (jobIds: string) =>
    doFetch(`/api/v3/variations/jobs?ids=${jobIds}`, "GET"),
  //#endregion

  //#region worksite defect
  getDefectByUser: (): Promise<DefectSignedRequestDto[]> =>
    doFetch(`/api/v3/defects/user`, "GET"),
  getDefectById: (defectId: number): Promise<DefectSignedRequestDto> =>
    doFetch(`/api/v3/defects/${defectId}`, "GET"),
  createDefect: (payload: CreateDefectDto) =>
    doFetch(`/api/v3/defects`, "POST", payload),
  sendDefect: (payload: { defectId: number; contactId: number }) =>
    doFetch(`/api/v3/defects/send`, "POST", payload),
  getDefectByUUID: (uuid: string): Promise<DefectDetailsDto> =>
    doFetch(`/api/v3/defects/sign/${uuid}`, "GET"),
  signDefect: (payload: SignDefectDto) =>
    doFetch(`/api/v3/defects/sign`, "POST", payload),
  signDefectRequest: (payload: SignDefectDto) =>
    doFetch(`/api/v3/defects/sign/request`, "POST", payload),
  rejectDefectRequest: (payload: RejectDefectRequestDto) =>
    doFetch(`/api/v3/defects/sign/reject`, "POST", payload),
  postDefectNote: (payload: CreateDefectNoteDto) =>
    doFetch(`/api/v3/note`, "POST", payload),
  getDefectNotes: (defectId: number) =>
    doFetch(`/api/v3/note/defect/${defectId}`, "GET"),
  getDefectByJobs: (jobIds: string) =>
    doFetch(`/api/v3/defects/jobs?ids=${jobIds}`, "GET"),
  //#endregion

  //#region Trades APIs
  trades: (): Promise<TradeDto[]> => doFetch(`/api/v3/trades`, "GET"),
  //#endregion Trade

  //#region Scope APIs
  scopes: (): Promise<ScopeDto[]> => doFetch(`/api/v5/trades/scopes`, "GET"),
  getClasses: (): Promise<classDto[]> =>
    doFetch(`/api/v3/trades/classes`, "GET"),
  getTradeScope: (id: number): Promise<ScopeDto[]> =>
    doFetch(`/api/v3/trades/trade/${id}/scopes`),
  postTrade: (data: TradeCreateTradeDto): Promise<TradeDto> =>
    doFetch(`/api/v3/trades/trade`, "POST", data),
  populateTrade: (data: TradePopulate): Promise<ScopeDto> =>
    doFetch(`/api/v3/trades/populate`, "POST", data),
  postScope: (data: TradeCreateScopeDto): Promise<ScopeDto> =>
    doFetch("/api/v3/trades/scope", "POST", data),
  getTradeById: (id: number): Promise<TradeDto> =>
    doFetch(`/api/v3/trades/${id}`, "GET"),
  archieveTrade: (id: number): Promise<TradeDto> =>
    doFetch(`/api/v3/trades/${id}`, "DELETE"),
  archieveScope: (id: number) =>
    doFetch(`/api/v3/trades/scope/${id}`, "DELETE"),
  //#endregion

  //region tradeV4 APIs
  getTradesV4: (): Promise<TradeDto[]> => doFetch("/api/v4/trades", "GET"),
  createTradeV4: (trade: createTradeDto): Promise<TradeDto> =>
    doFetch("/api/v4/trades", "POST", trade),
  archieveTradeV4: (id: number): Promise<TradeDto> =>
    doFetch(`/api/v4/trades/${id}`, "DELETE"),
  getTradeClasses: (): Promise<classDto[]> =>
    doFetch(`/api/v4/trades/class`, "GET"),
  getClassById: (id: number) => doFetch(`/api/v4/trades/class/${id}`, "GET"),
  getTradeByIdV4: (id: number): Promise<TradeDto> =>
    doFetch(`/api/v4/trades/${id}`, "GET"),
  postScopeV4: (scope: TradeCreateScopeDtoV4): Promise<ScopeDto> =>
    doFetch(`/api/v4/trades/scope`, "POST", scope),
  getScopesV4: (): Promise<ScopeDto[]> =>
    doFetch(`/api/v4/trades/scope`, "GET"),
  getTradeScopeV4: (id: number): Promise<ScopeDto[]> =>
    doFetch(`/api/v4/trades/${id}/scopes`, "GET"),
  getScopeById: (id: number): Promise<ScopeDto> =>
    doFetch(`/api/v4/trades/scope/${id}`, "GET"),
  postBulkScopes: (scopes: TradeCreateScopeDtoV4[]): Promise<ScopeDto[]> =>
    doFetch(`/api/v4/trades/scope/bulk`, "POST", scopes),
  editTrade: (id: number, trade: updateTradeDto): Promise<TradeDto> =>
    doFetch(`/api/v4/trades/${id}`, "PUT", trade),
  editBulkScopes: (scopes: TradeUpdateScopeDtoV4[]) =>
    doFetch(`/api/v4/trades/scope/bulk`, "PUT", scopes),
  editScopeById: (id: number, scope: TradeUpdateScopeDtoV4) =>
    doFetch(`/api/v4/trades/scope/${id}`, "PUT", scope),
  archieveScopeV4: (id: number): Promise<ScopeDto> =>
    doFetch(`/api/v4/trades/scope/${id}`, "DELETE"),
  createTradeScopeV4: (id: number, scope: TradeUpdateScopeDtoV4) =>
    doFetch(`/api/v4/trades/${id}/scope`, "POST", scope),
  populateTradeV4: (id: number, populate: TradePopulateV4) =>
    doFetch(`/api/v4/trades/${id}/populate`, "POST", populate),
  //#endregion

  //#region Induction
  getInductionCourses: (): Promise<InductionDto[]> =>
    doFetch(`/api/v3/lms/inductions`, "GET"),
  enrolCourse: (data: EnrolBodyDto) =>
    doFetch(`/api/v3/lms/inductions/enrol`, "POST", data),

  //#region Attendance
  getAttendance: (data): Promise<ListAttendanceResultDto> =>
    doFetch("/api/v3/attendance", "POST", data),
  actionTimeSheet: (data: ActionTimeUpdateRequest, id: number): Promise<any> =>
    doFetch(`/api/v3/timesheet/time/${id}`, "POST", data),
  postTimeSheet: (data: CreateTimeUpdateRequest) =>
    doFetch(`/api/v3/timesheet/time`, "POST", data),
  //#endregion Attendance

  //#region Users APIs
  getEmployeeById: (id: number): Promise<UserDto> =>
    doFetch(`/api/v3/user/${id}`, "GET"),
  getEmployeeList: (): Promise<MinimalUserDto[]> =>
    doFetch(`/api/v3/user/company`, "GET"),
  deleteUser: (id: number) => doFetch(`/api/v3/user/${id}`, "DELETE"),
  //#endregion

  // #start feature apis
  getFeatures: (): Promise<AccountFeatureDto[]> =>
    doFetch("/api/v3/feature", "GET"),
  updateFeature: (
    payload: UpdateAccountFeatureDto
  ): Promise<AccountFeatureDto> => doFetch("/api/v3/feature", "PUT", payload),
  // #end

  //schedule APIs
  updateStaffAssignmentInstance: (
    payload: {
      startDate: string;
      endDate: string;
    },
    jobId: number,
    instanceId: number
  ) => doFetch(`/api/v3/job/${jobId}/staff/${instanceId}`, "PUT", payload),
  getSchedules: ({
    view,
    worksites,
    projects,
    jobs,
    jobTypes,
    status,
    contractors,
    employees,
    startDate,
    endDate,
  }: ScheduleResponseParams): Promise<JobStaffDto[]> =>
    doFetch(
      `/api/v3/schedule?view=${view}${
        worksites?.filter((e) => e).length > 0
          ? `${worksites.reduce(
              (prev, curr) => prev + `&worksites[]=${curr}`,
              ""
            )}`
          : ""
      }${
        projects?.filter((e) => e).length > 0
          ? `${projects.reduce(
              (prev, curr) => prev + `&projects[]=${curr}`,
              ""
            )}`
          : ""
      }${
        jobs?.filter((e) => e).length > 0
          ? `${jobs.reduce((prev, curr) => prev + `&jobs[]=${curr}`, "")}`
          : ""
      }${
        contractors?.filter((e) => e).length > 0
          ? `${contractors.reduce(
              (prev, curr) => prev + `&contractors[]=${curr}`,
              ""
            )}`
          : ""
      }${
        employees?.filter((e) => e).length > 0
          ? `${employees.reduce(
              (prev, curr) => prev + `&employees[]=${curr}`,
              ""
            )}`
          : ""
      }${
        jobTypes?.filter((e) => e).length > 0
          ? `${jobTypes.reduce(
              (prev, curr) => prev + `&jobTypes[]=${curr}`,
              ""
            )}`
          : ""
      }${
        status?.filter((e) => e).length > 0
          ? `${status.reduce((prev, curr) => prev + `&status[]=${curr}`, "")}`
          : ""
      }${
        startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
      }`,
      "GET"
    ),

  getRawTimeSheetData: (payload: TimeSheetPayload) =>
    doFetch(`/api/v3/export/attendance/raw`, "POST", payload),
  getMyobTimeSheetData: (payload: TimeSheetPayload) =>
    doFetch(`/api/v3/export/attendance/myob`, "POST", payload),

  //# getDebuglog
  getDebugTransaction: (id: number) =>
    doFetch(`/api/v3/debug/transactions/${id}`, "GET"),
  getDebugBreaks: (id: number) => doFetch(`/api/v3/debug/breaks/${id}`, "GET"),

  //#endDebuglog

  //#region legacy methods ===============================================================
  /* ß */
  //#endregion legacy methods
};
