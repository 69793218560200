import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";

import auth from "../../services/auth";
import { ErrorResponse } from "../../services/fetcher";

import Address, { AddressData } from "components/address";
import { getAutocompleteType } from "components/contact/ContactForm";
import StepProgress from "components/core/StepProgress";

import AddressUtils from "../../utils/AddressUtils";
import { useTitle } from "../../utils/UseTitleUtils";
import { AuthData } from "reduxStore/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  AddOnListDto,
  PickPackDto,
  orgPackageDataType,
  pricingPlanDto,
  staffCardListDto,
} from "utils/PricingUtils";
import stripev4, { EstimateRequestDto } from "services/stripev4";
import { GAEvents, eventConstants } from "utils/GoogleAnalytics";

type SignUpInput = {
  firstName?: string;
  lastName?: string;
  email?: string;
  companyName?: string;
  phoneNumber?: string;
  password?: string;
  confirmPassword?: string;
  agreedPrivacyPolicy?: boolean;
  agreedTermsOfService?: boolean;
  stripeCalculationId?: string;
  global?: any;
};

type SignUpError = SignUpInput & {
  email?: string;
  address?: string;
};

interface SignUpProps {}

const defaultInput = {
  firstName: "",
  lastName: "",
  email: "",
  companyName: "",
  phoneNumber: null,
  password: "",
  confirmPassword: "",
  agreedPrivacyPolicy: false,
  agreedTermsOfService: false,
};

export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

declare type bundleDataDto = PickPackDto & {
  packData?: pricingPlanDto;
};

declare type featureDataDto = AddOnListDto & {
  packData?: pricingPlanDto;
};

declare type accountDataDto = staffCardListDto & {
  packData?: pricingPlanDto;
  maxEmployee?: number;
};

declare type organisationDataDto = orgPackageDataType & {
  planData?: pricingPlanDto;
  sahRate?: number;
  pahRate?: number;
  nahRate?: number;
  extraSetup?: number;
  extraTraining?: number;
};

declare type CartDto = {
  selectedOrganisation: organisationDataDto | undefined;
  selectedBundle: Array<bundleDataDto> | undefined;
  selectedFeature: Array<featureDataDto> | undefined;
  accounts: Array<accountDataDto> | undefined;
  extras: Array<accountDataDto> | undefined;
  stripeCalculationId: string;
  permanentWeblinkCount: number;
  temporaryWeblinkCount: number;
};

const SignUp = (props: SignUpProps) => {
  const { state }: { state: CartDto } = useLocation();

  const location = useLocation();

  const [inputs, setInputs] = useState<SignUpInput>(defaultInput);
  const [address, setAddress] = useState<AddressData | null>(null);
  const [errors, setErrors] = useState<SignUpError>({});
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const eventTracker = new GAEvents();

  useTitle("Account & Payment Information");

  if (!state?.selectedOrganisation) {
    return <Navigate to="/pricing" state={{ from: location }} replace />;
  }

  function isValid(e: React.FormEvent<HTMLFormElement>) {
    var err: SignUpError = {};

    if (inputs.firstName.trim() === "") {
      err.firstName = "First name is required.";
      e.stopPropagation();
    }

    if (inputs.lastName.trim() === "") {
      err.lastName = "Last name is required.";
      e.stopPropagation();
    }

    /* if (inputs.phoneNumber.trim() === "") {
      err.phoneNumber = "Phone number is required.";
      e.stopPropagation();
    } */

    if (inputs.email.trim() != "") {
      if (!emailRegex.test(inputs.email.trim())) {
        err.email = "Please enter valid Email address.";
        e.stopPropagation();
      }
    } else {
      err.email = "Email address is required.";
      e.stopPropagation();
    }

    // err.address = AddressUtils.verifyAddress(address);

    if (inputs.companyName.trim() === "") {
      err.companyName = "Company name is required.";
      e.stopPropagation();
    }

    if (inputs.password.trim() === "") {
      err.password = "Password is required.";
      e.stopPropagation();
    }

    if (inputs.confirmPassword.trim() === "") {
      err.confirmPassword = "Confirm password is required.";
      e.stopPropagation();
    }

    if (inputs.password.trim() != "" && inputs.confirmPassword.trim() != "") {
      if (inputs.password != inputs.confirmPassword) {
        err.password = "Password doesn't match";
        e.stopPropagation();
      }
    }

    if (
      err.firstName ||
      err.lastName ||
      err.companyName ||
      err.confirmPassword ||
      err.email ||
      err.password
      // err.phoneNumber ||
      // err.address
    ) {
      setErrors(err);
      return false;
    }

    return true;
  }

  function handleChange(
    fieldName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (fieldName == "password" || fieldName == "confirmPassword") {
      delete errors["password"];
      delete errors["confirmPassword"];
    } else {
      delete errors[fieldName];
    }
    delete errors.global;

    setInputs({
      ...inputs,
      [fieldName]: e.target.value,
    });
    setErrors(errors);
  }

  function errorMessage(field: string) {
    if (!errors) return null;
    if (typeof errors[field] === "undefined") return null;

    return (
      <Form.Control.Feedback className="font-color-red poppins" type="invalid">
        {errors[field]}
      </Form.Control.Feedback>
    );
  }

  const checkout = async (signUpResponse: AuthData) => {
    try {
      setIsLoading(true);
      const sahCount = state.accounts.find((e) =>
        e.lookupKey.includes("account_sah_additional")
      );
      const pahCount = state.accounts.find((e) =>
        e.lookupKey.includes("account_pah_additional")
      );
      const nahCount = state.accounts.find((e) =>
        e.lookupKey.includes("account_nah_additional")
      );
      const assistedSetup = state.extras.find((e) =>
        e.lookupKey.includes("extra_setup")
      );
      const assistedTraining = state.extras.find((e) =>
        e.lookupKey.includes("extra_training")
      );

      const payload: EstimateRequestDto = {
        level: state.selectedOrganisation.planData.level,
        bundles: [
          "business_essentials",
          ...state.selectedBundle
            .filter((e) => e.lookupKey !== "feature_audit_sharing")
            .map((e) => e.packData.key),
        ],
        features: state.selectedFeature.map((e) => e.packData.key),

        pahCount: pahCount.count || 0,
        nahCount: nahCount.count || 0,
        sahCount: sahCount.count || 0,
        additionalPermanentAuditLinks: state.permanentWeblinkCount,
        additionalTemporaryAuditLinks: state.temporaryWeblinkCount,
        assistedSetup: assistedSetup.count || 0,
        onsiteTrainingHours: assistedTraining.count || 0,
        couponId: "",
        country: "AU",
      };
      const response = await stripev4.stripeCheckout(
        payload,
        signUpResponse.token
      );

      window.location.href = response.url;
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  function doSignUp(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    /* if (form.current.checkValidity() === false) {
      e.stopPropagation();
      return;
    } */

    if (!isValid(e)) return;

    setIsLoading(true);
    auth
      .signup({
        ...inputs,
        companyName: inputs.companyName.trim() || "New Company",
        address:
          address.country !== "" || address.state !== "" ? address : null,
        stripeCalculationId: state.stripeCalculationId,
      })
      .then((res) => {
        // sign up done successfully and redirect it to stripe.
        checkout(res);
        eventTracker.trackFormSubmission(
          eventConstants.CART_CHECKOUT,
          "Continue to Checkout",
          {
            user_email: inputs.email,
          }
        );
      })
      .catch((err: any | ErrorResponse) => {
        let errors: SignUpInput = {};
        if (err.errors) {
          errors = err.errors;
          if (errors.email) {
            errors.email = (
              <span>
                It seems you already registered here, please{" "}
                <Link to={"/auth/login"}>Login</Link>
              </span>
            ) as any;
          }
          setErrors(err.errors);
        }

        console.error(err.code, err, err.errors);
        setIsLoading(false);
      });
  }

  /* const validInput = () => {
    const isValidArr = Object.entries(inputs).map(([key, value]) => {
      if (value) {
        return false;
      } else {
        return true;
      }
    });
    const isValidAddress = !(
      address?.country &&
      address?.postCode &&
      address?.state &&
      address?.streetName &&
      address?.suburb
    );

    return isValidArr.includes(true) || isValidAddress;
  }; */

  return (
    <div className="content-section signup poppins">
      <div className="align-items-lg-end align-items-start d-flex flex-column flex-lg-row justify-content-end">
        <div className="col-12 col-lg-6 pt-4 px-4">
          <StepProgress
            activeIndex={0}
            data={["PURCHASE", "CREATE ACCOUNT", "COMPANY"]}
          />
        </div>
      </div>
      <div className="col-12 d-flex flex-column justify-content-center">
        <div className="mt-3 px-4 px-sm-5">
          <h1 className="h1-text font-color-black mb-4">
            Account &amp; Payment Information
          </h1>
          <span>To get started you will need to set up your account:</span>

          <Form
            // ref={form}
            onSubmit={doSignUp}
            className="col-12 g-0 py-4 row validate"
          >
            <div className="col-12 col-lg-6 pe-0 pe-lg-3">
              <div className="row mb-3">
                <Form.Group className="col-6" controlId="firstName">
                  <Form.Label className="fw-bold">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete={getAutocompleteType("firstName")}
                    value={inputs.firstName}
                    onChange={(e) => handleChange("firstName", e as any)}
                    isInvalid={errors.firstName != undefined}
                  />
                  {errorMessage("firstName")}
                </Form.Group>
                <Form.Group className="col-6" controlId="lastName">
                  <Form.Label className="fw-bold">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete={getAutocompleteType("lastName")}
                    value={inputs.lastName}
                    onChange={(e) => handleChange("lastName", e as any)}
                    isInvalid={errors.lastName != undefined}
                  />
                  {errorMessage("lastName")}
                </Form.Group>
              </div>
              <Form.Group className="col-12 mb-3" controlId="company-name">
                <Form.Label className="fw-bold">Company Name:</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete={getAutocompleteType("companyName")}
                  value={inputs.companyName}
                  onChange={(e) => handleChange("companyName", e as any)}
                  isInvalid={errors.companyName != undefined}
                />
                {errorMessage("companyName")}
              </Form.Group>
              <Form.Group className="col-12 mb-3" controlId="identity">
                <Form.Label className="fw-bold">Email Address:</Form.Label>
                <Form.Control
                  type="email"
                  autoComplete={getAutocompleteType("email")}
                  value={inputs.email}
                  onChange={(e) => handleChange("email", e as any)}
                  isInvalid={errors.email != undefined}
                />
                {errorMessage("email")}
              </Form.Group>
              <Address
                required={false}
                onAddressChange={(address) => {
                  setAddress(address);
                  // delete errors["address"];
                  // setErrors(errors);
                }}
                defaultAddress={address}
              />
              {/* <p className="font-color-red poppins mb-3">
                {errors?.address || ""}
              </p> */}
              <Form.Group className="col-12 mb-3" controlId="phoneNumber">
                <Form.Label className="fw-bold">Phone Number:</Form.Label>
                <Form.Control
                  type="text"
                  minLength={8}
                  autoComplete={getAutocompleteType("phoneNumber")}
                  // value={new AsYouType().input(inputs.phoneNumber)}
                  value={inputs.phoneNumber}
                  onChange={(e) => handleChange("phoneNumber", e as any)}
                  // isInvalid={errors.phoneNumber != undefined}
                />
                {/* {errorMessage("phoneNumber")} */}
              </Form.Group>
            </div>
            <div className="col-12 col-lg-6">
              <Form.Group className="col-12 mb-3" controlId="password">
                <Form.Label className="fw-bold">Password:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    autoComplete={getAutocompleteType("password")}
                    value={inputs.password}
                    onChange={(e) => handleChange("password", e as any)}
                    isInvalid={errors.password != undefined}
                  />
                  <button
                    style={{
                      zIndex: 1,
                    }}
                    id="password-toggle"
                    className="bg-white input-group-text rounded-0"
                    aria-label="toggle password visibility"
                    title="toggle visibility"
                    type="button"
                    onKeyDown={(e) => {
                      if (e.key == " " || e.key == "Enter") {
                        e.currentTarget.click();
                        e.preventDefault();
                      }
                    }}
                    onClick={() => {
                      window.password_show_hide("#password");
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} id="show_eye" />
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      className="d-none"
                      id="hide_eye"
                    />
                  </button>
                  {errorMessage("password")}
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-12 mb-4" controlId="confirmPassword">
                <Form.Label className="fw-bold">Confirm Password:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="password"
                    autoComplete={getAutocompleteType("confirmPassword")}
                    value={inputs.confirmPassword}
                    onChange={(e) => handleChange("confirmPassword", e as any)}
                    isInvalid={errors.confirmPassword != undefined}
                  />
                  <button
                    style={{
                      zIndex: 1,
                    }}
                    id="confirmPassword-toggle"
                    className="bg-white input-group-text rounded-0"
                    aria-label="toggle password visibility"
                    title="toggle visibility"
                    type="button"
                    onKeyDown={(e) => {
                      if (e.key == " " || e.key == "Enter") {
                        e.currentTarget.click();
                        e.preventDefault();
                      }
                    }}
                    onClick={() => {
                      window.password_show_hide("#confirmPassword");
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} id="show_eye" />
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      className="d-none"
                      id="hide_eye"
                    />
                  </button>
                  {errorMessage("confirmPassword")}
                </InputGroup>
              </Form.Group>

              <Form.Group
                className="align-items-center checkbox d-flex mb-4"
                controlId="privacy-policy"
              >
                <Form.Check
                  checked={inputs.agreedPrivacyPolicy}
                  required
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      agreedPrivacyPolicy: e.target.checked,
                    })
                  }
                />
                <label
                  htmlFor="privacy-policy"
                  className="body-text poppins ps-2 d-inline-block"
                >
                  I have read and agree to the{" "}
                  <a
                    href="/privacy-policy"
                    role="link"
                    target="_blank"
                    onKeyDown={(e) => {
                      if (e.key == " " || e.key == "Enter") {
                        e.currentTarget.click();
                        e.preventDefault();
                      }
                    }}
                  >
                    Privacy Policy
                  </a>
                </label>
              </Form.Group>

              <Form.Group
                className="align-items-center checkbox d-flex mb-4"
                controlId="terms-and-condition"
              >
                <Form.Check
                  checked={inputs.agreedTermsOfService}
                  required
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      agreedTermsOfService: e.target.checked,
                    })
                  }
                />
                <label
                  htmlFor="terms-and-condition"
                  className="body-text poppins ps-2 d-inline-block"
                >
                  I have read and agree to the{" "}
                  <a
                    href="/terms"
                    role="link"
                    target="_blank"
                    onKeyDown={(e) => {
                      if (e.key == " " || e.key == "Enter") {
                        e.currentTarget.click();
                        e.preventDefault();
                      }
                    }}
                  >
                    {" "}
                    Terms and Conditions
                  </a>
                </label>
              </Form.Group>

              <button
                title="cofirm"
                aria-label="Confim Pay"
                // disabled={isLoading || validInput()}
                type="submit"
                className="btn col-12 primary-btn-lg py-2"
              >
                Confirm &amp; Pay
              </button>
            </div>
          </Form>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default SignUp;

export { SignUpInput };
