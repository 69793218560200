import {
  transitions,
  positions,
  Provider as AlertProvider,
  AlertProviderProps,
  AlertContainer,
} from "react-alert";
import { Provider } from "react-redux";
import { store } from "./src/redux/store";
import Layout from "./src/components/layout/Layout";
import AlertTemplate from "./src/components/alertTemplate/AlertTemplate";
import { BrowserRouter } from "react-router-dom";
import { registerLicense } from "@syncfusion/ej2-base";
import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "rc-slider/assets/index.css";
import { useDispatch } from "react-redux";
import { getAuthDetails, login } from "reduxStore/auth";
import { useSelector } from "react-redux";
import { GAEvents } from "utils/GoogleAnalytics";

// declare types for global
declare global {
  interface Window {
    loadAutoCompleteEvents: (parentClass: string) => void;
    equalHeightForRequired(): void;
    password_show_hide(inputId: string): void;
    scrollToViewAutocomplete(suggestionId: string): void;
    KS_ENV: "dev" | "prod" | undefined;
    alertContainer?: AlertContainer;
    audioRef: React.RefObject<HTMLAudioElement> | undefined;
  }
}

const options: AlertProviderProps = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 3000,
  offset: "12px",
  // you can also just use 'scale'
  transition: transitions.FADE,
  template: AlertTemplate,
};
const userString = localStorage.getItem("_auth");
const user = userString && JSON.parse(userString);

const checkOrigin = () => {
  if (window.origin.includes("dev")) {
    return "development";
  }
  if (
    window.location.host === "insite.work" ||
    window.location.host === "www.insite.work"
  ) {
    return "production";
  }
  if (window.origin.includes("staging")) {
    return "staging";
  }
};

{
  !window.location?.origin.includes("localhost") &&
    Sentry.init({
      dsn: "https://3e290f624bc34590aaee60ea6dda1fda@o4504012976488448.ingest.sentry.io/4504398944141312",
      environment: checkOrigin(),
      integrations: [
        new BrowserTracing({
          traceFetch: true,
        }),
        new Sentry.Replay({
          networkDetailAllowUrls: [
            /^https?:\/\/[a-zA-Z0-9.-]*.insite\.work\/.*/,
          ],
          networkRequestHeaders: ["Cache-Control"],
          networkResponseHeaders: ["Referrer-Policy"],
        }),
      ],
      tracesSampleRate: 1.0,
      initialScope: {
        user: user,
      },
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.2,
    });
}

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VlhiQlVPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSX9TdEdgW3hbcXBWQmM="
);

function App() {
  console.log(process.env.NODE_ENV);
  const eventTracker = new GAEvents();

  useEffect(() => {
    if (!eventTracker.reactGa.isInitialized) {
      eventTracker.initializeGA4();
    }
  }, []);

  return (
    <AlertProvider {...options}>
      <Provider store={store}>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </Provider>
    </AlertProvider>
  );
}

export default App;
