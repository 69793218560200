import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAudioSrc,
  getPlayStatus,
  setAudioSrc,
  stopAudio,
  toggleAudio,
} from "reduxStore/audioplayer";
import { FileDto, TranscriptDto } from "services/users";
import AudioIcons from "utils/AudioIcons";
import { getTranscriptStatusText } from "utils/CommonUtils";

type Props = {
  className?: string;
  disabled?: boolean;
  isPlaying?: boolean;
  onClickPlay?: (e?: FileDto) => void;
  onClickStop?: () => void;
  audioData?: FileDto;
  refetchAudio?: boolean;
};

const AudioChipWithTranscript = ({
  onClickPlay,
  onClickStop,
  isPlaying,
  audioData,
}: Props) => {
  const dispatch = useDispatch();
  const [PlayerStatus, setPlayerStatus] = useState(isPlaying);
  const [currentAudioFile, setCurrentAudioFile] = useState<FileDto>();
  const [isExpanded, setIsExpanded] = useState(false);
  const audioSrc = useSelector(getAudioSrc);
  const playStatus = useSelector(getPlayStatus);

  useEffect(() => {
    setPlayerStatus(isPlaying && playStatus);
  }, [playStatus]);

  useEffect(() => {
    setCurrentAudioFile(audioData);
  }, [audioData]);

  return (
    <div className="w-100 light-gray-bg">
      <div className="border border-dark d-flex justify-content-center gap-3 py-1">
        <button
          title="rewind"
          aria-label="rewind audio track"
          className="border-0 bg-transparent"
          onClick={() => {
            if (currentAudioFile.inlineUrl !== "") {
              dispatch(setAudioSrc(""));
              setTimeout(() => {
                dispatch(setAudioSrc(currentAudioFile?.inlineUrl!));
              }, 100);
            }
          }}
        >
          <AudioIcons.AudioRewind className="image-style" />
        </button>
        {!PlayerStatus ? (
          <button
            className="border-0 bg-transparent"
            title="play"
            aria-label="Play Audio"
            onClick={() => {
              if (currentAudioFile.inlineUrl !== "") {
                if (audioSrc === currentAudioFile?.inlineUrl) {
                  dispatch(toggleAudio(currentAudioFile?.inlineUrl));
                } else {
                  dispatch(setAudioSrc(currentAudioFile?.inlineUrl));
                }
                onClickPlay && onClickPlay(currentAudioFile);
              }
            }}
          >
            <AudioIcons.AudioPlay className="image-style" />
          </button>
        ) : (
          <button
            className="border-0 bg-transparent"
            title="pause"
            aria-label="Pause Audio"
            onClick={() => {
              if (currentAudioFile?.inlineUrl !== "") {
                if (audioSrc === currentAudioFile?.inlineUrl) {
                  dispatch(toggleAudio(currentAudioFile?.inlineUrl));
                } else {
                  dispatch(setAudioSrc(currentAudioFile?.inlineUrl));
                }
                onClickPlay && onClickPlay(currentAudioFile);
              }
            }}
          >
            <AudioIcons.AudioPause
              className="image-style"
              style={{ padding: "2px" }}
            />
          </button>
        )}
        <button
          className="border-0 bg-transparent"
          title="stop"
          aria-label="stop audio"
          onClick={() => {
            dispatch(stopAudio());
            onClickStop && onClickStop();
          }}
        >
          <AudioIcons.AudioStop className="image-style" />
        </button>
      </div>
      <div className="border border-dark border-top-0 py-1 px-3">
        <div className="d-flex justify-content-between">
          <div className="align-items-baseline d-flex gap-2">
            <span className="h3-text">Transcript: </span>
            {!isExpanded && (
              <span
                className="d-block file-text-overflow"
                style={{ maxWidth: "190px" }}
              >
                {typeof currentAudioFile?.transcript === "string" &&
                  (currentAudioFile?.transcript || "Transcript not available")}
                {typeof currentAudioFile?.transcript === "object" &&
                currentAudioFile?.transcript?.status === "COMPLETED"
                  ? currentAudioFile?.transcript?.text ||
                    "Transcript not available"
                  : `Transcript generation ${getTranscriptStatusText(
                      (currentAudioFile?.transcript as TranscriptDto)?.status
                    )}`}
              </span>
            )}
          </div>
          <button
            aria-expanded={isExpanded}
            aria-label="view transcript"
            title="expand"
            className="bg-transparent border-0"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} />
          </button>
        </div>
        {isExpanded && (
          <>
            <p className="my-3">
              {typeof currentAudioFile?.transcript === "string" &&
                (currentAudioFile?.transcript || "Transcript not available")}
              {typeof currentAudioFile?.transcript === "object" &&
                (currentAudioFile?.transcript?.status === "COMPLETED"
                  ? currentAudioFile?.transcript?.text ||
                    "Transcript not available"
                  : `Transcript generation ${getTranscriptStatusText(
                      (currentAudioFile?.transcript as TranscriptDto)?.status
                    )}`)}
            </p>
            <p className="fw-600 body-text text-danger">
              Disclaimer: This text was transcribed using AI and the accuracy of
              the transcription cannot be guaranteed. Please use the audio
              recording to validate the information.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default AudioChipWithTranscript;
