import { useDispatch, useSelector } from "react-redux";
import users, { FileDto, TranscriptDto } from "services/users";

import {
  getAudioSrc,
  getPlayStatus,
  setAudioSrc,
  stopAudio,
  toggleAudio,
} from "../../redux/audioplayer";
import { Overlay } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import React from "react";
import AudioIcons from "utils/AudioIcons";
import { getTranscriptStatusText } from "utils/CommonUtils";

export declare type AudioChipProps = {
  className?: string;
  url?: string;
  disabled?: boolean;
  isPlayDto?: boolean;
  onClickPlay?: (e?: FileDto) => void;
  onClickStop?: () => void;
  audioData?: FileDto;
  refetchAudio?: boolean;
  showTransacript?: boolean;
};

const AudioChip = ({
  className,
  showTransacript = true,
  isPlayDto,
  onClickPlay,
  onClickStop,
  audioData,
  refetchAudio,
  ...props
}: AudioChipProps) => {
  const dispatch = useDispatch();
  const audioSrc = useSelector(getAudioSrc);
  const playStatus = useSelector(getPlayStatus);
  const [PlayerStatus, setPlayerStatus] = useState(isPlayDto);
  const [showTooltip, setShowTooltip] = useState(false);
  const buttonRef = useRef(null);
  const componentWrapper = useRef(null);
  const [currentAudioFile, setCurrentAudioFile] = useState<FileDto>();

  const isDisabled =
    componentWrapper.current?.classList.contains("div-disabled");
  const getLatestFile = async (id: number) => {
    try {
      const response: FileDto = await users.getFileById(id);
      setCurrentAudioFile(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setPlayerStatus(isPlayDto && playStatus);
  }, [playStatus]);

  useEffect(() => {
    if (audioData?.id && refetchAudio) {
      getLatestFile(audioData.id);
    } else {
      setCurrentAudioFile(audioData);
    }
  }, [audioData]);

  return (
    <div
      ref={componentWrapper}
      className={`d-inline-block light-gray-bg px-2 py-1 rounded mt-2 d-flex ${
        className ?? ""
      }`}
      style={{ width: "max-content" }}
    >
      <button
        className="border-0 bg-transparent"
        style={{
          opacity: isDisabled ? "0.3" : "1",
        }}
        title="rewind"
        aria-label="rewind audio track"
        onClick={() => {
          if (currentAudioFile?.inlineUrl != "") {
            dispatch(setAudioSrc(""));
            setTimeout(() => {
              dispatch(setAudioSrc(currentAudioFile?.inlineUrl!));
            }, 100);
          }
        }}
      >
        <AudioIcons.AudioRewind className="image-style" />
      </button>
      {!PlayerStatus ? (
        <button
          className="border-0 bg-transparent"
          style={{
            opacity: isDisabled ? "0.3" : "1",
          }}
          title="play"
          aria-label="Play Audio"
          onClick={() => {
            if (currentAudioFile?.inlineUrl != "") {
              if (audioSrc == currentAudioFile?.inlineUrl) {
                dispatch(toggleAudio(currentAudioFile?.inlineUrl!));
              } else {
                dispatch(setAudioSrc(currentAudioFile?.inlineUrl!));
              }
              onClickPlay && onClickPlay(currentAudioFile);
            }
          }}
        >
          <AudioIcons.AudioPlay className="image-style" />
        </button>
      ) : (
        <button
          className="border-0 bg-transparent"
          title="pause"
          aria-label="Pause Audio"
          style={{
            opacity: isDisabled ? "0.3" : "1",
          }}
          onClick={() => {
            if (currentAudioFile?.inlineUrl != "") {
              if (audioSrc == currentAudioFile?.inlineUrl) {
                dispatch(toggleAudio(currentAudioFile?.inlineUrl!));
              } else {
                dispatch(setAudioSrc(currentAudioFile?.inlineUrl!));
              }
              onClickPlay && onClickPlay(currentAudioFile);
            }
          }}
        >
          <AudioIcons.AudioPause
            className="image-style"
            style={{ padding: "2px" }}
          />
        </button>
      )}
      <button
        className="border-0 bg-transparent"
        title="stop"
        aria-label="stop audio"
        style={{
          opacity: isDisabled ? "0.3" : "1",
        }}
        onClick={() => {
          dispatch(stopAudio());
          onClickStop && onClickStop();
        }}
      >
        <AudioIcons.AudioStop className="image-style" />
      </button>
      {showTransacript && (
        <div>
          <button
            style={{
              opacity: isDisabled ? "0.3" : "1",
            }}
            title="view transcript"
            aria-label="view transcript"
            ref={buttonRef}
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onKeyDown={(e) => e.key === "Escape" && setShowTooltip(false)}
            onFocus={() => setShowTooltip(true)}
            className="border-0 bg-transparent position-relative"
          >
            <AudioIcons.AudioTranscript className="image-style" />
          </button>
          <Overlay
            show={showTooltip}
            target={buttonRef.current}
            placement="top"
          >
            {({
              placement: _placement,
              arrowProps: _arrowProps,
              show: _show,
              popper: _popper,
              hasDoneInitialMeasure: _hasDoneInitialMeasure,
              ...props
            }) => (
              <div
                {...props}
                style={{
                  position: "absolute",
                  borderRadius: 3,
                  ...props.style,
                  maxWidth: "15rem",
                  zIndex: 2000,
                }}
                className="position-absolute text-white"
              >
                <div className="bg-dark m-2 rounded-2">
                  <p
                    className="m-0"
                    style={{
                      padding: "2px 10px",
                    }}
                  >
                    {typeof currentAudioFile?.transcript === "string" &&
                      (currentAudioFile?.transcript || "N/A")}
                    {typeof currentAudioFile?.transcript === "object" &&
                    currentAudioFile?.transcript?.status === "COMPLETED"
                      ? currentAudioFile?.transcript?.text || "N/A"
                      : `${getTranscriptStatusText(
                          (currentAudioFile?.transcript as TranscriptDto)
                            ?.status
                        )}`}
                  </p>
                </div>
              </div>
            )}
          </Overlay>
        </div>
      )}
    </div>
  );
};

export default AudioChip;
