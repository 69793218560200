import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swms, { SWMSResponse, SWMSTemplate } from "services/swms";
import CenterLoading from "components/core/CenterLoading";
import { useSelector } from "react-redux";
import { userProfile } from "reduxStore/auth";
import { useDispatch } from "react-redux";
import { useTitle } from "utils/UseTitleUtils";
import { Form } from "react-bootstrap";
import FileSelect from "components/core/FileSelect";
import users, { FileDto } from "services/users";

type Props = {};

declare type SectionThreeViewWorksParams = {
  jobId?: number;
  worksiteId?: number;
  projectId?: number;
  swmsPerson?: SWMSResponse;
};

const SectionThreeTemplate = (props: Props) => {
  const [operationType, setOperationType] = useState("Select Existing");
  const [showJobDetails, setShowJobDetails] = useState(true);
  const [showLegislation, setShowLegislation] = useState(true);
  const [swmsTemplateData, setSwmsTemplateData] = useState<SWMSTemplate[]>([]);
  const [template, setTemplate] = useState<SWMSTemplate>();
  const [name, setName] = useState("");
  const [templateFile, setTemplateFile] = useState<File>();
  const [error, setError] = useState<string>();
  const [fileError, setFileError] = useState<string>();
  const [displayName, setDisplayName] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hidePages, setHidePages] = useState<number[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const state: SectionThreeViewWorksParams = location.state;
  useTitle("Generate SWMS - Section 3");

  const profile = useSelector(userProfile);
  const { jobId, swmsPerson } = state;
  const hidePagesValue = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const getAllSwmsTemplateData = async () => {
    try {
      setIsLoading(true);
      const response = await swms.getSWMSTemplate();
      setSwmsTemplateData(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadDoc = async () => {
    if (!templateFile) {
      return undefined;
    }
    setIsLoading(true);

    const formData = new FormData();
    formData.append("fileType", "swms_template_pdf");
    formData.append("file", templateFile);

    try {
      const response: FileDto = await users.uploadFile(formData);
      return response.id;
    } catch (err) {
      setFileError(err.message);
      console.log(err);
      setIsLoading(false);
      return undefined;
    }
  };

  const submitDetails = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (name === "" || name === undefined) {
      setError("Please enter name.");
      return;
    }
    createTemplate();
  };

  const createTemplate = async () => {
    setIsLoading(true);
    const fileId = await uploadDoc();
    try {
      const sendPayload = await swms.createSWMSTemplate({
        name,
        fileId,
        hidePages,
        showJobDetails,
        showLegislation,
      });
      if (sendPayload) {
        getAllSwmsTemplateData();
        setTemplate(sendPayload);
        setDisplayName(true);
      }
      setName("");
      setTemplateFile(undefined);
      setHidePages([]);
      setShowJobDetails(true);
      setShowLegislation(true);
      setError(undefined);
      setFileError(undefined);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const sendTemplateId = async () => {
    setIsLoading(true);
    try {
      const sendPayload = await swms.updateSWMS(swmsPerson.id, {
        templateId: template.id,
        step: "set_template",
      });

      navigate(`/swms/generate-swms`, {
        state: {
          jobId: jobId,
          swmsPerson,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllSwmsTemplateData();
  }, []);

  function errorMessage() {
    if (!error) return null;
    if (typeof error === "undefined") return null;

    return (
      <Form.Control.Feedback className="font-color-red poppins" type="invalid">
        {error}
      </Form.Control.Feedback>
    );
  }

  return (
    <div className="content-section p-3 p-md-5">
      <CenterLoading isVisible={isLoading} />
      <div className="d-flex flex-column flex-fill">
        <h1 className="h1-text font-color-black">Generate SWMS - Section 3</h1>
        <div className="light-gray-bg p-3 d-flex flex-fill">
          <div className="border border-dark m-auto white-bg col-12">
            <div className="black-bg p-3 text-center">
              <span className="font-color-white ">
                Create new template/ select existing template:
              </span>
            </div>
            <div className="h-auto w-100">
              <div className="col-12 row m-0 my-4">
                <Form.Group className="mb-4 poppins text-center">
                  <Form.Label className="fw-bold col-12 d-block">
                    Please select from below operation to perform.
                  </Form.Label>
                  <Form.Check
                    inline
                    label="Select Existing"
                    value="Select Existing"
                    name="SWMSTemplate"
                    type={"radio"}
                    checked={operationType === "Select Existing"}
                    id={`inline-radio-Existing`}
                    className="me-4"
                    onChange={(e) => {
                      setOperationType(e.target.value);
                      setDisplayName(false);
                      setTemplate(undefined);
                    }}
                  />
                  <Form.Check
                    inline
                    name="SWMSTemplate"
                    label="Create new template"
                    value="Create new template"
                    type={"radio"}
                    id={`inline-radio-new-template`}
                    checked={operationType === "Create new template"}
                    onChange={(e) => {
                      setOperationType(e.target.value);
                      setTemplate(undefined);
                    }}
                  />
                </Form.Group>
                {operationType === "Select Existing" ? (
                  <>
                    <Form.Group
                      className="col-md-6 col-12 m-auto"
                      controlId="existingCompany"
                    >
                      <Form.Label>Select Template:</Form.Label>
                      <Form.Select
                        onChange={(e) => {
                          setTemplate(
                            swmsTemplateData.find(
                              (ele) => ele.id === Number(e.target.value)
                            )
                          );
                        }}
                      >
                        <option value="none">Select Existing Template</option>
                        {swmsTemplateData.map(
                          (e: SWMSTemplate, index: number) => {
                            return (
                              <option key={`template-${index}`} value={e.id}>
                                {e.name}
                              </option>
                            );
                          }
                        )}
                      </Form.Select>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    {displayName ? (
                      <div className="col-md-6 col-12 m-auto">
                        {template.name} template is created.
                      </div>
                    ) : (
                      <Form
                        className="col-md-6 col-12 m-auto"
                        onSubmit={submitDetails}
                      >
                        <Form.Group className="mb-3" controlId="name">
                          <Form.Label>Name:</Form.Label>
                          <Form.Control
                            value={name}
                            type="text"
                            className="poppins"
                            isInvalid={error !== undefined}
                            onChange={(e) => {
                              setError(undefined);
                              setName(e.target.value);
                            }}
                          />
                          {errorMessage()}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hidePages">
                          <Form.Label>Hide first pages from PDF:</Form.Label>
                          <Form.Select
                            onChange={(e) => {
                              const pages = [];
                              if (Number(e.target.value) > 0) {
                                for (
                                  let index = 1;
                                  index <= Number(e.target.value);
                                  index++
                                ) {
                                  pages.push(index);
                                }
                                setHidePages(pages);
                              } else {
                                setHidePages(pages);
                              }
                            }}
                          >
                            {hidePagesValue.map((e: number, index: number) => {
                              return (
                                <option key={`hidePage-${index}`} value={e}>
                                  {e}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold col-12 d-block">
                            Show insite Job Details:
                          </Form.Label>
                          <Form.Check
                            inline
                            label="Yes"
                            name="ShowJob"
                            type={"radio"}
                            checked={showJobDetails === true}
                            id={`inline-job-Yes`}
                            className="me-4"
                            onChange={() => {
                              setShowJobDetails(true);
                            }}
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="ShowJob"
                            type={"radio"}
                            id={`inline-job-No`}
                            checked={showJobDetails == false}
                            onChange={() => {
                              setShowJobDetails(false);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold col-12 d-block">
                            Show summary page:
                          </Form.Label>
                          <Form.Check
                            inline
                            label="Yes"
                            name="ShowSummary"
                            type={"radio"}
                            checked={showLegislation === true}
                            id={`inline-summary-Yes`}
                            className="me-4"
                            onChange={() => {
                              setShowLegislation(true);
                            }}
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="ShowSummary"
                            type={"radio"}
                            id={`inline-summary-No`}
                            checked={showLegislation == false}
                            onChange={() => {
                              setShowLegislation(false);
                            }}
                          />
                        </Form.Group>
                        <FileSelect
                          accept=".zip,.doc,.docx,.txt,.pdf"
                          buttonName="Upload File"
                          name="templateFile"
                          formGroupClassName="my-4"
                          controlId="templateFile"
                          error={fileError}
                          onSelectFile={(file) => {
                            setTemplateFile(file);
                          }}
                        />
                        <button
                          title="submit"
                          aria-label="Create Template"
                          disabled={isLoading || !templateFile}
                          type="submit"
                          className="btn primary-btn col-sm-auto nav-btn w-100"
                        >
                          Create
                        </button>
                      </Form>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`d-flex justify-content-between row g-0 mt-5`}>
        <div className="col-12 col-sm-3">
          <button
            type="button"
            title="navigate"
            aria-label="Navigate Back"
            className="btn primary-btn-black h-100 w-100"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
        <div className="col-12 col-sm-3">
          <button
            type="button"
            disabled={!template}
            title="next"
            aria-label="Next Step"
            className="btn primary-btn h-100 w-100"
            onClick={sendTemplateId}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionThreeTemplate;
